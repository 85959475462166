import axios from "axios";
import NProgress from "nprogress";
import appState from "../state/AppStateContainer";
import _ from "lodash";

class BaseService {
  constructor() {
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        if (config.progressBar) {
          NProgress.start();
        }
        if (appState.state.token)
          config.headers.authorization =
            "Bearer " + appState.state.token?.accessToken;
        return config;
      },
      function (error) {
        // Do something with request error
        console.log("axiosRequestError", error);
        NProgress.done();
        console.error(error);
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        NProgress.done();
        return response;
      },
      async function (error) {
        if (
          _.get(error, "response.status") === 401 &&
          error?.response?.data?.error !== "error.sessionExpired"
        ) {
          window.location = "/permissions-changed";
          // await appState.logout();
        } else if (
          error?.response?.data?.error === "error.sessionExpired" ||
          error?.response?.data?.error?.name === "TokenExpiredError"
        ) {
          window.location = "/session-expired";
        }

        if (error.message === "Network Error") {
          console.error("axioserror", error);

          window.location = "/internet-error";
        }
        // Do something with response error
        NProgress.done();
        console.error(error);
        return Promise.reject(error);
      }
    );
  }
}

export default BaseService;
