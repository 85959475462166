import React from "react";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import { Modal } from "react-bootstrap";
import FieldRow from "./FieldRow";
import { NotificationManager } from "react-notifications";
import Services from "../../services";
import _ from "lodash";
import { Yup } from "../../util";

const AddExpressProgress = ({
  show,
  onCancel,
  carData,
  phoneNumber,
  intl,
  selectedTab,
  progress,
  source,
}) => {
  const data = (progress && progress[0]) || [];

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!carData.carPlateNumber || !phoneNumber) return;

    try {
      await Services.sendFastCollectInfo({
        ...carData,
        phoneNumber,
        appointmentId:
          selectedTab === "cndAppointments"
            ? data && data[0]?.appointmentId
            : "",
        ...values,
        source,
      });
      onCancel("add");

      NotificationManager.success(
        intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      NotificationManager.error(
        intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => onCancel("add")}
      size="lg"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <h4>
          <FormattedMessage
            id="fastCollect.sendRepairProgress"
            defaultMessage="Send car repair progress"
          />
        </h4>
      </Modal.Header>
      <Formik
        initialValues={{
          notes: "",
          repairImageOne: "",
          repairImageTwo: "",
          repairImageThree: "",
          repairImageFour: "",
          video: "",
          repairCompleted: false,
        }}
        validationSchema={Yup.object().shape({
          notes: Yup.string()
            .label(
              intl.formatMessage({
                id: "fastCollect.notes",
                defaultMessage: "Repair notes is",
              })
            )
            .trim()
            .required(),
          repairImageOne: Yup.string()
            .label(
              intl.formatMessage({
                id: "fastCollect.image",
                defaultMessage: "Repair Image one is",
              })
            )
            .required(),
        })}
        onSubmit={handleSubmit}
      >
        {(formProps) => {
          return (
            <Form>
              <Modal.Body>
                <FieldRow
                  type="textarea"
                  name="notes"
                  defaultLabel="Repair Notes"
                  labelId="fastCollect.fastCollectNotes"
                  placeholderId="fastCollect.fastCollectNotes"
                  defaultPlaceholder="Information about repair progress"
                  formikProps={formProps}
                  showRequiredFlag={true}
                  colSize={3}
                />
                <FieldRow
                  type="file"
                  name="repairImageOne"
                  labelId="fastCollect.fastCollectImage"
                  defaultLabel="Repair Image One"
                  image={true}
                  showThumbnail={true}
                  showRequiredFlag={true}
                  forCarousel={true}
                  cameraIcon={true}
                  formikProps={formProps}
                  colSize={3}
                />
                <FieldRow
                  type="file"
                  name="repairImageTwo"
                  labelId="fastCollect.fastCollectImage"
                  defaultLabel="Repair Image Two"
                  image={true}
                  showThumbnail={true}
                  showRequiredFlag={false}
                  forCarousel={true}
                  cameraIcon={true}
                  formikProps={formProps}
                  colSize={3}
                />
                <FieldRow
                  type="file"
                  name="repairImageThree"
                  labelId="fastCollect.fastCollectImage"
                  defaultLabel="Repair Image Three"
                  image={true}
                  showThumbnail={true}
                  showRequiredFlag={false}
                  forCarousel={true}
                  cameraIcon={true}
                  formikProps={formProps}
                  colSize={3}
                />
                <FieldRow
                  type="file"
                  name="repairImageFour"
                  labelId="fastCollect.fastCollectImage"
                  defaultLabel="Repair Image Four"
                  image={true}
                  showThumbnail={true}
                  showRequiredFlag={false}
                  forCarousel={true}
                  cameraIcon={true}
                  formikProps={formProps}
                  colSize={3}
                />
                <FieldRow
                  type="video"
                  name="video"
                  labelId="fastCollect.fastCollectVideo"
                  defaultLabel="Repair Video"
                  showRequiredFlag={false}
                  showThumbnail={true}
                  formikProps={formProps}
                  colSize={3}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="add-car-modal-footer">
                  <label className="complete-repair">
                    <input
                      type="checkbox"
                      name="repairCompleted"
                      onChange={(e) =>
                        formProps.setFieldValue(
                          "repairCompleted",
                          e.target.checked
                        )
                      }
                    />
                    <FormattedMessage
                      id="fastCollect.CompleteRepair"
                      defaultMessage="Complete Repair"
                    />
                  </label>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={formProps.isSubmitting}
                  >
                    <FormattedMessage
                      id="global.submit"
                      defaultMessage="Submit"
                    />
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddExpressProgress;
