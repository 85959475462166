export const Activities = [
  {
    id: "6b429601-5120-11e9-81fb-19dfb1bf9916",
    moduleId: "6b429600-5120-11e9-81fb-19dfb1bf9916", //dashboard enum
    name: "All",
  },
  {
    id: "6b429601-5120-11e9-81fb-2933cc366292",
    moduleId: "6b429600-5120-11e9-81fb-19dfb1bf9916", //dashboard update appointment enum
    name: "Update Appointment",
  },
  {
    id: "6b429601-5220-12e9-82fb-2933cc366293",
    moduleId: "6b429600-5120-11e9-81fb-19dfb1bf9916", //dashboard update appointment enum
    name: "See more Appointment Details",
  },
  {
    id: "bff92cf6-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf5-4f35-11e9-a194-3798c4448c7f", //Service Provider enum
    name: "New Service Provider",
  },
  {
    id: "bff92cf7-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf5-4f35-11e9-a194-3798c4448c7f",
    name: "Update Service Provider / Edit ", //Service Provider enum
  },
  {
    id: "bff92cf4-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf5-4f35-11e9-a194-3798c4448c7f", //Service Provider enum
    name: "Filter & Search",
  },
  {
    id: "396aae04-ca90-4b89-9fdb-a65573a278c4",
    moduleId: "bff92cf5-4f99-11e9-a194-3798c4448c7f", //Branches enum
    name: "Table",
  },
  {
    id: "b29a6915-4c05-43be-a7e0-beb7217d5a52",
    moduleId: "bff92cf5-4f99-11e9-a194-3798c4448c7f", //Branches enum
    name: "Add Branch",
  },
  {
    id: "e4f2ad67-1fa3-4267-92b6-866988be222e",
    moduleId: "bff92cf5-4f99-11e9-a194-3798c4448c7f", //Branches enum
    name: "Update Branch",
  },
  // {
  //   id: "1936262b-296e-4692-813a-3fca1cb107fd",
  //   moduleId: "bff92cf5-4f99-11e9-a194-3798c4448c7f", //Branches enum
  //   name: "Delete Branch",
  // },
  {
    id: "b29a6915-4c05-43be-a7e0-ceb7617d5a52",
    moduleId: "bff92cf5-4f99-11e9-a194-3798c4449c7f", //Variants enum
    name: "View Variant",
  },
  {
    id: "b29a6915-4c05-43be-a7e0-ceb7217d5a52",
    moduleId: "bff92cf5-4f99-11e9-a194-3798c4449c7f", //Variants enum
    name: "Add Variant",
  },
  {
    id: "b29a6915-4c05-43be-a7e0-ceb7217d5a53",
    moduleId: "bff92cf5-4f99-11e9-a194-3798c4449c7f", //Variants enum
    name: "Update Variant",
  },
  {
    id: "bff954a8-4f35-11e9-a194-3798c2348c7f",
    moduleId: "bff95405-4f35-11e9-a194-3798c4448c7f",
    name: "Table",
  },
  // {
  //   id: "bff95404-4f35-11e9-a194-3798c4448c7f",
  //   moduleId: "bff95405-4f35-11e9-a194-3798c4448c7f",
  //   name: "Add New Car",
  // },
  {
    id: "bff95406-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff95405-4f35-11e9-a194-3798c4448c7f",
    name: "Update Car / Edit",
  },
  {
    id: "bff95407-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff95405-4f35-11e9-a194-3798c4448c7f",
    name: "Filter & Search",
  },

  {
    id: "bff97b13-f110-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Home",
  },
  {
    id: "bff97b13-f111-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "About Us",
  },
  {
    id: "bff97b13-f112-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Offers",
  },
  {
    id: "bff97b13-f116-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Partners",
  },
  {
    id: "bff92cf2-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Schedule",
  },
  {
    id: "bff92cf0-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Estimaiton",
  },
  {
    id: "bff905e7-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Bring To The Expert",
  },
  {
    id: "bfw22v22-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Location",
  },
  {
    id: "bfw22v23-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Search",
  },
  {
    id: "bff905e6-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Track",
  },
  {
    id: "bgc108r7-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Contact Us",
  },
  {
    id: "bmw108r7-4f35-11e9-a194-3798c4448c8f",
    moduleId: "qssh95409-4f35-11e9-a194-3798c4448c8h", // pages enum
    name: "Career",
  },
  {
    id: "ghq108r7-4f35-11e9-a194-3798c4448c8f",
    moduleId: "vbbh95409-4f35-11e9-a194-3798c4448c9h", // pages enum
    name: "Area Of Interest",
  },
  {
    id: "5084a3bf-719e-44e1-be92-4kls4abc480f",
    moduleId: "9972b7a0-8843-49a8-adf0-2e1p9ed04808", //Area of interest
    name: "Add Blog",
  },
  {
    id: "ntr108r7-4f35-11e9-a194-3798c4448c4p",
    moduleId: "pchh95409-4f35-11e9-a194-3798c4448c80", // pages enum
    name: "Users",
  },
  {
    id: "9966b7a0-8843-49a8-adf0-8e5f9ed04802",
    moduleId: "bff92cf1-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Common",
  },
  {
    id: "9967b7a0-8843-49a8-adf0-8e5f9ed04803",
    moduleId: "bff95405-4f35-11e9-a194-3798c4452c7f", // content enum
    name: "Home",
  },
  {
    id: "9968b7a0-8843-49a8-adf0-8e5f9ed04804",
    moduleId: "bff95405-4f35-11e9-a194-3798c4452c7f", // content enum
    name: "Media",
  },
  {
    id: "9969b7a0-8843-49a8-adf0-8e5f9ed04805",
    moduleId: "bff95405-4f35-11e9-a194-3798c4452c7f", // content enum
    name: "Kaizen",
  },
  {
    id: "9970b7a0-8843-49a8-adf0-8e5f9ed04806",
    moduleId: "bff95405-4f35-11e9-a194-3798c4452c7f", // content enum
    name: "Contact us",
  },
  {
    id: "9971b7a0-8843-49a8-adf0-8e5f9ed04807",
    moduleId: "bff95405-4f35-11e9-a194-3798c4452c7f", // content enum
    name: "Customer SMS Notification",
  },
  {
    id: "9972b7a0-8843-49a8-adf0-8e5f9ed04808",
    moduleId: "bff95405-4f35-11e9-a194-3798c4452c7f", // content enum
    name: "Customer Email Notification",
  },
  {
    id: "mpk8ded0-4f35-11e9-a194-3798c4448c03",
    moduleId: "str8ded0-4f35-11e9-a194-3798c4448c02", //location form
    name: "Update Location /Edit ",
  },
  {
    id: "pot8ded0-4f35-11e9-a194-3798c4448c02",
    moduleId: "str8ded0-4f35-11e9-a194-3798c4448c02", //location form
    name: "New Location",
  },
  {
    id: "jkt108r7-4f35-11e9-a194-3798c4448c7r",
    moduleId: "tweh95409-4f35-11e9-a194-3798c4448c8h", // pages enum
    name: "Footer",
  },
  {
    id: "jkt108r7-4f35-12e9-a194-3798c4448c7r",
    moduleId: "tweh95309-4f35-11e9-a194-3798c4448c8h", // pages enum
    name: "Email Notification",
  },
  {
    id: "cbv209r0-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff95409-4f35-11e9-a194-3798c4448c7f", // pages enum
    name: "Autohub PWA",
  },
  {
    id: "bff95408-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff95409-4f35-11e9-a194-3798c4448c7f",
    name: "CMS",
  },
  {
    id: "cbv209r0-4f36-11e9-a204-3798c4448c8f",
    moduleId: "bff95409-4f36-11e9-a194-3798c4448c8f", // pages enum
    name: "Blog",
  },
  {
    id: "bff95408-4f35-12e9-a214-3798c4448c9f",
    moduleId: "bff95409-4f36-11e9-a194-3798c4448c8f",
    name: "Tag",
  },
  {
    id: "bff905e4-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff905e5-4f35-11e9-a194-3798c4448c7f", // Permissions Roles enum
    name: "Roles",
  },
  {
    id: "bff905e8-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff905e5-4f35-11e9-a194-3798c4448c7f", // Permissions Admins enum
    name: "Admins",
  },
  {
    id: "bff905e1-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff905e0-4f35-11e9-a194-3798c4448c7f", //services
    name: "Update Services /Edit ",
  },
  {
    id: "bff8ded0-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff905e0-4f35-11e9-a194-3798c4448c7f", //Area of interest
    name: "New Service",
  },
  {
    id: "bff905e2-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff905e0-4f35-11e9-a194-3798c4448c7f", //aut0-services
    name: "Filter & Search",
  },
  {
    id: "bff915e2-4f35-11e9-a195-3798c4449c7f",
    moduleId: "bff905e0-4f35-11e9-a194-3798c4448c7f", //aut0-services
    name: "Clone Service",
  },

  // Subscription Packages
  {
    id: "bff8ded0-4f35-12e9-a195-3799c4448c7f",
    moduleId: "bff916e0-4f36-11e9-a194-3798c4458c7f",
    name: "New Subscription",
  },
  {
    id: "bff906e0-5f35-12e9-a194-3899c4448c7f",
    moduleId: "bff916e0-4f36-11e9-a194-3798c4458c7f",
    name: "Filter & Search",
  },
  {
    id: "bff925e3-4f36-12e9-a196-3898c4449c7f",
    moduleId: "bff916e0-4f36-11e9-a194-3798c4458c7f",
    name: "Update & Edit",
  },

  // Subscribed Offers
  {
    id: "bff935e3-4f36-13e9-a196-3998c5459c7f",
    moduleId: "bff916e0-4f37-12e1-a195-3799c4458c7f",
    name: "All",
  },

  {
    id: "474d31d4-114a-4dd1-9e7f-2933cc366289",
    moduleId: "6ea0a0c9-f5d9-45b2-9cee-caf75638df2a", //schedule appointment  enum
    name: "Table",
  },
  {
    id: "474d31d4-114a-4dd2-9e7f-2933cc366289",
    moduleId: "6ea0a0c9-f5d9-45b2-9cee-caf75638df2a", //schedule appointment  enum
    name: "Update / Edit / Add Appointment",
  },
  {
    id: "474d31d4-114a-4dd139e7f-2933cc366289",
    moduleId: "6ea0a0c9-f5d9-45b2-9cee-caf75638df2a", //schedule appointment  enum
    name: "Schedule New Appointment Button",
  },
  {
    id: "474d31d4-114a-4dd139e7f-2933cc366290",
    moduleId: "6ea0a0c9-f5d9-45b2-9cee-caf75638df2a", //schedule appointment  enum
    name: "Cancel Appointment Button",
  },
  {
    id: "474d31d5-114a-4dd139e7f-2933cc366291",
    moduleId: "6ea0a0c9-f5d9-45b2-9cee-caf75638df2a", //schedule appointment  enum
    name: "Fast Drop Button",
  },
  {
    id: "474d32d6-124a-4dd139e7f-2933cc366292",
    moduleId: "6ea0a0c9-f5d9-45b2-9cee-caf75638df2a", //schedule appointment  enum
    name: "Export to Excel",
  },
  {
    id: "bff95400-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff97b10-4f35-11e9-a194-3798c4448c7f", // Estiamtion Request enum
    name: "All",
  },
  {
    id: "bff95401-4f36-11e9-a194-3798c4449c8f",
    moduleId: "bff97b10-4f35-11e9-a194-3798c4448c7f", // Estiamtion Request enum
    name: "Export to Excel",
  },
  {
    id: "bff96411-4f36-11e9-a194-3798c4459c9f",
    moduleId: "bff97b10-4f35-11e9-a194-3798c4448c7f", // Estiamtion Request enum
    name: "Close Estimation",
  },
  // {
  //   id: "bff95402-4f35-11e9-a194-3798c4450c7f",
  //   moduleId: "bff97b10-4f35-11e9-a194-3798c4448c7f", // Estiamtion Request enum
  //   name: "Add / View Comment",
  // },
  {
    id: "bff95402-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff33c32-4f35-11e9-a194-3798c4448c7f", // AutoHub Web Translation enum
    name: "All",
  },
  // {
  //   id: "ccd108r7-4f35-11e9-a194-3798c4448c8f",
  //   moduleId: "bydh95409-4f35-11e9-a194-3798c4448c3m", // Locations
  //   name: "Locations",
  // },
  {
    id: "9b36d38c-bf07-44cd-92d9-b5c622d55885", // customers
    moduleId: "b0b21a3e-1b29-4ddf-9a8f-e5a9e803353a",
    name: "All",
  },
  {
    id: "9b36d39c-bf08-44cd-92d9-b5c622d55886", // customers
    moduleId: "b0b21a3e-1b29-4ddf-9a8f-e5a9e803353a",
    name: "Export to Excel",
  },
  {
    id: "9b36d39d-bf08-44cd-92d9-b5c622d55887", // settings
    moduleId: "bff95404-4f35-11e9-a194-3798c4451c7f",
    name: "Update",
  },
  {
    id: "9b36d32d-bf09-44ef-92d0-b5c622d55447", // settings
    moduleId: "bff95404-4f35-11e9-a194-3798c4451c7f",
    name: "view",
  },
  {
    id: "9b36d40c-bf07-44cd-92d9-b5c622d55887", // Track - All
    moduleId: "bff95401-4f35-11e9-a194-3798c4449c7f",
    name: "All",
  },
  {
    id: "9f5ee282-d2c3-4c98-b46c-370ec529ba07",
    moduleId: "bff95401-4f35-11e9-a194-3798c4449c7f", //Track -> Add Car Progress Button
    name: "Add Car Progress Button",
  },
  {
    id: "2cc1a425-a1fc-4dae-80d5-89ace7b22b93",
    moduleId: "bff95401-4f35-11e9-a194-3798c4449c7f", //Track -> View Repair History Button
    name: "View Repair History Button",
  },
  {
    id: "3313b3e0-e64a-4466-9e6e-7c0362e4b67a",
    moduleId: "bff95401-4f35-11e9-a194-3798c4449c7f", //Track -> Add Recommendation Button
    name: "Add Recommendation Button",
  },
  {
    id: "32e105c0-dee9-4398-a6a3-5907d20703be",
    moduleId: "bff95401-4f35-11e9-a194-3798c4449c7f", //Track -> View Recommendation Offers Button
    name: "View Recommended Offers Button",
  },
  {
    id: "8f7b80c9-d3c7-41ca-b0d5-7698e8f0f56d", // Track -> Appointments
    moduleId: "bff95401-4f35-11e9-a194-3798c4449c7f",
    name: "Appointments Tab",
  },
  {
    id: "250c823a-b070-4723-802c-b8ac803e2920",
    moduleId: "bff95401-4f35-11e9-a194-3798c4449c7f", //Track -> Estimations Tab
    name: "Estimations Tab",
  },
  {
    id: "8ec43742-189a-4f75-9f6d-83a2f21f911e",
    moduleId: "bff95401-4f35-11e9-a194-3798c4449c7f", //Track -> Quotations Tab
    name: "Quotations Tab",
  },
  {
    id: "f5d8ade3-dbe6-4e74-945e-acc09c3355c9",
    moduleId: "3f2f9a01-a728-4d5b-8032-f8fd9df75a20", //Trck Repair Feedback
    name: "All",
  },
  {
    id: "117fa5cd-d54c-49c8-b78d-c4671f8a2f73",
    moduleId: "3f2f9a01-a728-4d5b-8032-f8fd9df75a20", //Assign Service Advisor Button
    name: "Assign Service Advisor Button",
  },
  {
    id: "14e3a208-64f0-4f13-8c79-5128e236c1b2",
    moduleId: "4d10c8dc-e59c-461d-8ea8-5d3123d89613", //Upsell Recommendation
    name: "All",
  },
  {
    id: "9b36d41c-bf07-44cd-92d9-b5c622d55888", // Required doc
    moduleId: "bff95403-4f35-11e9-a194-3798c4450c7f",
    name: "All",
  },
  {
    id: "bff97b15-4f35-11e9-a194-3798c4448c7f", // offers
    moduleId: "bff97b16-4f35-11e9-a194-3798c4448c7f",
    name: "Table",
  },
  {
    id: "bff97b15-4f35-11e9-a194-3798c4448cpp", // offers
    moduleId: "bff97b16-4f35-11e9-a194-3798c4448c7f",
    name: "Add / Update",
  },
  {
    id: "bff97b16-3f35-11e9-b194-3798c4338c7f", // subscription
    moduleId: "sff97b16-4f35-11s9-a188-3798c4338c7f",
    name: "Table",
  },
  {
    id: "bff97b16-4fe5-11e9-b194-3798c4338cpp", // subscription
    moduleId: "sff97b16-4f35-11s9-a188-3798c4338c7f",
    name: "Add / Update",
  },
  {
    id: "757f817d-0bd1-410c-aca3-679b65fcdafa", // kaizen
    moduleId: "5074a3bf-719e-44e1-be92-4efe4abc470f",
    name: "Table",
  },
  {
    id: "bff97b15-4f35-11e9-a194-3798c4448cqq", // kaizen
    moduleId: "5074a3bf-719e-44e1-be92-4efe4abc470f",
    name: "Add / Update",
  },
  {
    id: "bff97b15-4f35-11e9-a194-3798c44s1s1s", // schedule email
    moduleId: "5074a3bf-719e-44e1-be92-4efe4abq1q1q",
    name: "Table",
  },
  {
    id: "bff97b15-4f35-11e9-a194-3798c444e1e1", // schedule email
    moduleId: "5074a3bf-719e-44e1-be92-4efe4abq1q1q",
    name: "Add / Update",
  },
  // {
  //   id: "6b429602-5120-11e9-81fb-19dfb1bf9917", // contact us
  //   moduleId: "5074a4bf-719e-44e1-be92-4efe4abc471f",
  //   name: "All",
  // },
  {
    id: "6b429603-5120-11e9-81fb-19dfb1bf9918", // contact us
    moduleId: "5074a4bf-719e-44e1-be92-4efe4abc471f",
    name: "Customer Care",
  },
  {
    id: "6b429604-5120-11e9-81fb-19dfb1bf9919", // contact us
    moduleId: "5074a4bf-719e-44e1-be92-4efe4abc471f",
    name: "Marketing",
  },
  {
    id: "6b429605-5120-11e9-81fb-19dfb1bf9920", // contact us
    moduleId: "5074a4bf-719e-44e1-be92-4efe4abc471f",
    name: "Career",
  },
  {
    id: "6b429616-5221-12e9-81fb-19dfb1bf9920", // contact us
    moduleId: "5074a4bf-719e-44e1-be92-4efe4abc471f",
    name: "VIP and Loyalty",
  },
  {
    id: "6b429616-5221-13e9-81fb-19dfb1bf9921", // contact us
    moduleId: "5074a4bf-719e-44e1-be92-4efe4abc471f",
    name: "Delete Account",
  },
  {
    id: "6b429616-5221-13e9-81fb-19dfb1bf9821", // contact us
    moduleId: "5074a4bf-719e-44e1-be92-4efe4abc471f",
    name: "Checked-In Vehicles",
  },
  {
    id: "bff8ded0-4f35-11e9-a194-3798c4448c71",
    moduleId: "bff905e0-4f35-11e9-a194-3798c4448123",
    name: "New Category",
  },
  {
    id: "bff905e1-4f35-11e9-a194-3798c4448c72",
    moduleId: "bff905e0-4f35-11e9-a194-3798c4448123",
    name: "Update Category /Edit / Reject",
  },
  {
    id: "bff905e2-4f35-11e9-a194-3798c4448c73",
    moduleId: "bff905e0-4f35-11e9-a194-3798c4448123",
    name: "Filter & Search",
  },

  {
    id: "bff8ded0-4f35-11e9-a195-3898c4548c71",
    moduleId: "bff915e0-4f36-12e9-a194-3799c4448123",
    name: "New Service Category",
  },
  {
    id: "bff905e1-4f36-12e9-a195-3899c4448c73",
    moduleId: "bff915e0-4f36-12e9-a194-3799c4448123",
    name: "Update Service Category /Edit / Reject",
  },

  {
    id: "bff95400-4f35-11e9-a194-3798c4448111",
    moduleId: "bff95401-4f35-11e9-a194-3798c4448c7f",
    name: "Add New Promo Code",
  },
  {
    id: "bff95402-4f35-11e9-a194-3798c4448222",
    moduleId: "bff95401-4f35-11e9-a194-3798c4448c7f",
    name: "Filter & Search",
  },
  {
    id: "bff95403-4f35-11e9-a194-3798c4448333",
    moduleId: "bff95401-4f35-11e9-a194-3798c4448c7f",
    name: "Update Promotion Code / Edit",
  },
  {
    id: "bff95404-4f35-11e9-a194-3798b3338111",
    moduleId: "bff95401-4f35-11e9-a194-3798d4448c7f",
    name: "Add New Careem Promo Code",
  },
  {
    id: "bff95405-4f35-11e9-a194-3798b3338222",
    moduleId: "bff95401-4f35-11e9-a194-3798d4448c7f",
    name: "Filter & Search",
  },
  {
    id: "bff95406-4f35-11e9-a194-3798c3338333",
    moduleId: "bff95401-4f35-11e9-a194-3798d4448c7f",
    name: "Update Careem Promo Code / Edit",
  },
  {
    id: "11f905e4-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff905e5-4f35-11e9-a194-3798c444qwer",
    name: "New Product",
  },
  {
    id: "22f905e7-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff905e5-4f35-11e9-a194-3798c444qwer",
    name: "Filter & Search",
  },
  {
    id: "33f905e8-4f35-11e9-a194-3798c4448c7f",
    moduleId: "bff905e5-4f35-11e9-a194-3798c444qwer",
    name: "Update Product / Edit",
  },
  {
    id: "33f915e8-4f35-11e9-a194-3798c4449c7f",
    moduleId: "5084a3bf-719e-44e1-be92-4efe4abc480f",
    name: "Table",
  },
  {
    id: "33f925e8-4f35-11e9-a194-3798c4450c7f",
    moduleId: "5084a3bf-719e-44e1-be92-4efe4abc480f",
    name: "Filter and Search",
  },
  {
    id: "33f935e8-4f35-11e9-a194-3798c4451c7f",
    moduleId: "5084a3bf-719e-44e1-be92-4efe4abc480f",
    name: "Mark as delivered Accessibility",
  },
  // {
  //   id: "33f936e8-4f35-11e9-a194-3798c4452c8f",
  //   moduleId: "5084a3bf-719e-44e1-be92-4efe4abc480f",
  //   name: "Mark as completed Accessibility",
  // },
  {
    id: "33f935e8-4f35-11e9-a194-3798c4452c7f",
    moduleId: "5084a3bf-719e-44e1-be92-4efe4abc480f",
    name: "Mark as completed Accessibility",
  },
  {
    id: "33f935e8-4f35-11e9-a194-3798c4453c7f",
    moduleId: "5084a3bf-719e-44e1-be92-4efe4abc480f",
    name: "Display Products",
  },
  {
    id: "33f955e8-4f35-11e9-a194-3798c4553c7f",
    moduleId: "5084a3bf-719e-44e1-be92-4efe4abc480f",
    name: "Upsell Service Assign",
  },
  {
    id: "33f955e8-4f36-11e9-a194-3798c4554c7f",
    moduleId: "5084a3bf-719e-44e1-be92-4efe4abc480f",
    name: "Export to Excel",
  },
  {
    id: "33f955e8-4f37-12e9-a195-3799c4564c7f",
    moduleId: "5084a3bf-719e-44e1-be92-4efe4abc480f",
    name: "Refund",
  },
  {
    id: "33f936e8-4f35-11e9-a194-3798c4451c7f",
    moduleId: "5085a3bf-719e-44e1-be92-4efe4abc481f",
    name: "Filter and Search",
  },
  {
    id: "33f925e8-4f35-11e9-a194-3798c4451c7f",
    moduleId: "5085a3bf-719e-44e1-be92-4efe4abc481f",
    name: "Table",
  },
  {
    id: "33f925e9-4f35-11e9-a194-3798c4551c7f",
    moduleId: "5085a3bf-719e-44e1-be92-4efe4abc481f",
    name: "Export to Excel",
  },
  {
    id: "9966b7a0-8833-49a8-adf0-8e5f9ed04802",
    moduleId: "5085a3bf-719e-44e1-be92-4dfe4abc481f", // Create Quotation
    name: "All",
  },
  {
    id: "cc9518cf-6798-4001-8236-9ef9c4e56333",
    moduleId: "0b806c29-7611-47df-9650-97fe4796bef7", // Requested Quotations
    name: "All",
  },
  {
    id: "cc9519cf-6799-4001-8237-9ef9c4e56333",
    moduleId: "0b806c29-7612-47df-9651-97fe4807bef7", // Cities
    name: "All",
  },
  {
    id: "474d31d4-114a-4dd139e7f-2933cc366424",
    moduleId: "bff92cf5-4f99-11e9-a194-3089c4498c7f", // Collection Appointment
    name: "All",
  },
  {
    id: "474d31d4-114a-4dd139e7f-2933cc366349",
    moduleId: "bff92cf5-4f99-11e9-a194-3089c4498c7f", // Collection Appointment (Excel)
    name: "Export to Excel",
  },
];

export const ActivitiesEnum = {
  orders_table: "33f915e8-4f35-11e9-a194-3798c4449c7f",
  orders_filter_and_search: "33f925e8-4f35-11e9-a194-3798c4450c7f",
  order_mark_as_delivered: "33f935e8-4f35-11e9-a194-3798c4451c7f",
  // order_mark_as_completed: "33f936e8-4f35-11e9-a194-3798c4452c8f",
  display_offers: "33f935e8-4f35-11e9-a194-3798c4452c7f",
  display_products: "33f935e8-4f35-11e9-a194-3798c4453c7f",
  upsell_service_assign: "33f955e8-4f35-11e9-a194-3798c4553c7f",
  orders_export_to_excel: "33f955e8-4f36-11e9-a194-3798c4554c7f",
  orders_refund: "33f955e8-4f37-12e9-a195-3799c4564c7f",
  orders_report_table: "33f925e8-4f35-11e9-a194-3798c4451c7f",
  orders_report_export_to_excel: "33f925e9-4f35-11e9-a194-3798c4551c7f",
  orders_report_filter_and_search: "33f936e8-4f35-11e9-a194-3798c4451c7f",

  dashboard__all: "6b429601-5120-11e9-81fb-19dfb1bf9916",
  dashboard_update_appointment: "6b429601-5120-11e9-81fb-2933cc366292",
  dashboard_see_more_appointment_details:
    "6b429601-5220-12e9-82fb-2933cc366293",

  service_provider__new_service_provider:
    "bff92cf6-4f35-11e9-a194-3798c4448c7f",
  service_provider__update_service_provider_edit_reset_password_login_as:
    "bff92cf7-4f35-11e9-a194-3798c4448c7f",
  service_provider__filter_and_search: "bff92cf4-4f35-11e9-a194-3798c4448c7f",

  branch__view_branch: "396aae04-ca90-4b89-9fdb-a65573a278c4",
  branch__add_branch: "b29a6915-4c05-43be-a7e0-beb7217d5a52",
  branch__update_branch: "e4f2ad67-1fa3-4267-92b6-866988be222e",
  branch__delete_branch: "1936262b-296e-4692-813a-3fca1cb107fd",

  variant__add_variant: "b29a6915-4c05-43be-a7e0-ceb7217d5a52",
  variant__update_variant: "b29a6915-4c05-43be-a7e0-ceb7217d5a53",
  variant__view_variant: "b29a6915-4c05-43be-a7e0-ceb7617d5a52",

  order__detail: "b29a6915-4c05-43be-a7e0-ceb7217d5a55",

  cars__table: "bff954a8-4f35-11e9-a194-3798c2348c7f",
  cars__add_new_car: "bff95404-4f35-11e9-a194-3798c4448c7f",
  cars__update_car_edit: "bff95406-4f35-11e9-a194-3798c4448c7f",
  cars__filter_and_search: "bff95407-4f35-11e9-a194-3798c4448c7f",

  pages__home: "bff97b13-f110-11e9-a194-3798c4448c7f",
  pages__about: "bff97b13-f111-11e9-a194-3798c4448c7f",
  pages__offer: "bff97b13-f112-11e9-a194-3798c4448c7f",
  pages__our_partners: "bff97b13-f116-11e9-a194-3798c4448c7f",
  pages__schedule: "bff92cf2-4f35-11e9-a194-3798c4448c7f",
  pages__estimaiton: "bff92cf0-4f35-11e9-a194-3798c4448c7f",
  pages__bring_to_the_expert: "bff905e7-4f35-11e9-a194-3798c4448c7f",
  pages__location: "bfw22v22-4f35-11e9-a194-3798c4448c7f",
  pages__search: "bfw22v23-4f35-11e9-a194-3798c4448c7f",
  pages__track: "bff905e6-4f35-11e9-a194-3798c4448c7f",
  pages__contact_us: "bgc108r7-4f35-11e9-a194-3798c4448c7f",
  pages__career: "bmw108r7-4f35-11e9-a194-3798c4448c8f",
  pages__footer: "jkt108r7-4f35-11e9-a194-3798c4448c7r",
  pages__email: "jkt108r7-4f35-12e9-a194-3798c4448c7r",
  pages__common: "9966b7a0-8843-49a8-adf0-8e5f9ed04802",
  pages__quotation: "9966b7a0-8833-49a8-adf0-8e5f9ed04802",

  permissions__roles: "bff905e4-4f35-11e9-a194-3798c4448c7f",
  permissions__admins: "bff905e8-4f35-11e9-a194-3798c4448c7f",

  services__new_service: "bff8ded0-4f35-11e9-a194-3798c4448c7f",
  kaizen_new: "bff8ded0-4f35-11e9-a194-3795c4448c7f",
  services__update_service_edit: "bff905e1-4f35-11e9-a194-3798c4448c7f",
  kaizen_edit: "bff905e1-4f35-11e9-a194-3798c4448c7f",
  services__filter_and_search: "bff905e2-4f35-11e9-a194-3798c4448c7f",
  services_clone_service: "bff915e2-4f35-11e9-a195-3798c4449c7f",

  // subscription child nodes
  subscription_new_package: "bff8ded0-4f35-12e9-a195-3799c4448c7f",
  subscription_filter_search: "bff906e0-5f35-12e9-a194-3899c4448c7f",
  subscription_update_edit: "bff925e3-4f36-12e9-a196-3898c4449c7f",
  subscribed_offers_all: "bff935e3-4f36-13e9-a196-3998c5459c7f",

  schedule_appointment_all: "474d31d4-114a-4dd1-9e7f-2933cc366289",
  schedule_appointment_update: "474d31d4-114a-4dd2-9e7f-2933cc366289",
  schedule_appointment_add: "474d31d4-114a-4dd139e7f-2933cc366289",
  cancel_appointment_button: "474d31d4-114a-4dd139e7f-2933cc366290",
  fast_drop_button: "474d31d5-114a-4dd139e7f-2933cc366291",
  schedule_appointment_export_to_excel: "474d32d6-124a-4dd139e7f-2933cc366292",
  collectionAppointment_request_all: "474d31d4-114a-4dd139e7f-2933cc366424",
  collectionAppointment_request_export_to_excel:
    "474d31d4-114a-4dd139e7f-2933cc366349",
  customers_all: "9b36d38c-bf07-44cd-92d9-b5c622d55885",
  customers_export_to_excel: "9b36d39c-bf08-44cd-92d9-b5c622d55886",

  Offers_table: "bff97b15-4f35-11e9-a194-3798c4448c7f",
  Offers_add_update: "bff97b15-4f35-11e9-a194-3798c4448cpp",

  Subscriptions_table: "bff97b16-3f35-11e9-b194-3798c4338c7f",
  Subscriptions_add_update: "bff97b16-4fe5-11e9-b194-3798c4338cpp",

  kaizen_table: "757f817d-0bd1-410c-aca3-679b65fcdafa",
  kaizen_add_update: "bff97b15-4f35-11e9-a194-3798c4448cqq",

  schedule_email_table: "bff97b15-4f35-11e9-a194-3798c44s1s1s",
  schedule_email_add_update: "bff97b15-4f35-11e9-a194-3798c444e1e1",

  estimation_request_all: "bff95400-4f35-11e9-a194-3798c4448c7f",
  estimation_request_export_to_excel: "bff95401-4f36-11e9-a194-3798c4449c8f",
  estimation_request_close: "bff96411-4f36-11e9-a194-3798c4459c9f",
  estimation_request_add_view_comment: "bff95402-4f35-11e9-a194-3798c4450c7f",

  auto_web_translation_all: "bff95402-4f35-11e9-a194-3798c4448c7f",

  change_password__web: "cbv209r0-4f35-11e9-a194-3798c4448c7",
  change_password__cms: "bff95408-4f35-11e9-a194-3798c4448c7f",

  knowledge_hub_blog: "cbv209r0-4f36-11e9-a204-3798c4448c8f",
  knowledge_hub_tag: "bff95408-4f35-12e9-a214-3798c4448c9f",

  area_of_interest__new: "qrf8ded0-4f35-11e9-a194-3798c4448c2l",
  area_of_interest__update_edit: "typ905e1-4f35-11e9-a194-3798c4448c7f",

  location__new: "pot8ded0-4f35-11e9-a194-3798c4448c02",
  location__update_edit: "mpk8ded0-4f35-11e9-a194-3798c4448c03",

  createOffer: "potsdqqwded0-4f35-11e9-a194-3798c4448c02",

  contact_us_all: "6b429602-5120-11e9-81fb-19dfb1bf9917",
  contact_us_customer_care: "6b429603-5120-11e9-81fb-19dfb1bf9918",
  contact_us_marketing: "6b429604-5120-11e9-81fb-19dfb1bf9919",
  contact_us_career: "6b429605-5120-11e9-81fb-19dfb1bf9920",
  contact_us_vip_and_loyalty: "6b429616-5221-12e9-81fb-19dfb1bf9920",
  contact_us_delete_account: "6b429616-5221-13e9-81fb-19dfb1bf9921",
  contact_us_checked_in_vehicles: "6b429616-5221-13e9-81fb-19dfb1bf9821",

  categories__new_category: "bff8ded0-4f35-11e9-a194-3798c4448c71",
  categories__update_category_edit_reject:
    "bff905e1-4f35-11e9-a194-3798c4448c72",
  categories__filter_and_search: "bff905e2-4f35-11e9-a194-3798c4448c73",

  service_categories__new: "bff8ded0-4f35-11e9-a195-3898c4548c71",
  service_categories__update_category_edit_reject:
    "bff905e1-4f36-12e9-a195-3899c4448c73",

  promo_management__add_new_promo_code: "bff95400-4f35-11e9-a194-3798c4448111",
  promo_management__filter_and_search: "bff95402-4f35-11e9-a194-3798c4448222",
  promo_management__update_promotion_code_edit:
    "bff95403-4f35-11e9-a194-3798c4448333",

  careem_promo__bulk_upload_careem_promo_code:
    "bff95404-4f35-11e9-a194-3798b3338111",
  careem_promo__filter_and_search: "bff95405-4f35-11e9-a194-3798b3338222",
  careem_promo__update_careem_promo_code_edit:
    "bff95406-4f35-11e9-a194-3798c3338333",

  products__new_product: "11f905e4-4f35-11e9-a194-3798c4448c7f",
  products__filter_and_search: "22f905e7-4f35-11e9-a194-3798c4448c7f",
  products__update_product_edit: "33f905e8-4f35-11e9-a194-3798c4448c7f",

  //track
  add_car_progress_button: "9f5ee282-d2c3-4c98-b46c-370ec529ba07",
  view_repair_history_button: "2cc1a425-a1fc-4dae-80d5-89ace7b22b93",
  add_recommendation_button: "3313b3e0-e64a-4466-9e6e-7c0362e4b67a",
  view_recommended_Offers_button: "32e105c0-dee9-4398-a6a3-5907d20703be",
  view_appointment_tab: "8f7b80c9-d3c7-41ca-b0d5-7698e8f0f56d",
  view_estimation_tab: "250c823a-b070-4723-802c-b8ac803e2920",
  view_quotaion_tab: "8ec43742-189a-4f75-9f6d-83a2f21f911e",

  //repair feedback
  view_assign_service_advisor_button: "117fa5cd-d54c-49c8-b78d-c4671f8a2f73",

  //settings
  settings_update: "9b36d39d-bf08-44cd-92d9-b5c622d55887",
  settings_view: "9b36d32d-bf09-44ef-92d0-b5c622d55447",

  required_documents_all: "9b36d41c-bf07-44cd-92d9-b5c622d55888",
  content_home: "9967b7a0-8843-49a8-adf0-8e5f9ed04803",
  content_media: "9968b7a0-8843-49a8-adf0-8e5f9ed04804",
  content_kaizen: "9969b7a0-8843-49a8-adf0-8e5f9ed04805",
  content_contact_us: "9970b7a0-8843-49a8-adf0-8e5f9ed04806",
  content_customer_sms_notification: "9971b7a0-8843-49a8-adf0-8e5f9ed04807",
  content_customer_email_notification: "9972b7a0-8843-49a8-adf0-8e5f9ed04808",

  new_blog: "9972b7a0-8843-49a8-adf0-2e1p9ed04808",
};

export const Modules = [
  { id: "6b429600-5120-11e9-81fb-19dfb1bf9916", name: "Dashboard" },

  // { id: "bff9540b-4f35-11e9-a194-3798c4448c7f", name: "Analytics" },

  // { id: "bff92cf5-4f35-11e9-a194-3798c4448c7f", name: "Service Provider" },

  { id: "bff92cf5-4f99-11e9-a194-3798c4448c7f", name: "Branches" },

  { id: "bff92cf5-4f99-11e9-a194-3798c4449c7f", name: "Variants" },

  { id: "bff95405-4f35-11e9-a194-3798c4448c7f", name: "Cars" },

  // { id: "bff92cf1-4f35-11e9-a194-3798c4448c7f", name: "Pages" },
  { id: "bff905e5-4f35-11e9-a194-3798c4448c7f", name: "Permissions" },

  { id: "bff905e0-4f35-11e9-a194-3798c4448c7f", name: "Services" },

  { id: "bff916e0-4f36-11e9-a194-3798c4458c7f", name: "Subscription Packages" },
  { id: "bff916e0-4f37-12e1-a195-3799c4458c7f", name: "Subscribed Offers" },
  // { id: "bff905e0-4f35-11e9-a194-3798c4448c9f", name: "Contacts" },

  { id: "6ea0a0c9-f5d9-45b2-9cee-caf75638df2a", name: "Schedule Appointment" },
  {
    id: "bff97b10-4f35-11e9-a194-3798c4448c7f",
    name: "Estimation Request",
  },
  {
    id: "bff33c32-4f35-11e9-a194-3798c4448c7f",
    name: "Autohub Web Translation",
  },
  { id: "bff95409-4f35-11e9-a194-3798c4448c7f", name: "Change Password" },
  { id: "bff95409-4f36-11e9-a194-3798c4448c8f", name: "Knowledge Hub" },
  // {
  //   id: "cgh95409-4f35-11e9-a194-3798c4448c7f", name: "Change Password Web"
  // },
  // {
  //   id: "qssh95409-4f35-11e9-a194-3798c4448c8h",
  //   name: "Career"
  // },
  // {
  //   id: "vbbh95409-4f35-11e9-a194-3798c4448c9h",
  //   name: "Area Of Interest"
  // },
  // {
  //   id: "bydh95409-4f35-11e9-a194-3798c4448c3m",
  //   name: "Locations",
  // },
  {
    id: "b0b21a3e-1b29-4ddf-9a8f-e5a9e803353a",
    name: "Customers",
  },
  {
    id: "bff97b16-4f35-11e9-a194-3798c4448c7f",
    name: "Offers",
  },
  {
    id: "sff97b16-4f35-11s9-a188-3798c4338c7f",
    name: "Subscriptions",
  },
  {
    id: "5074a3bf-719e-44e1-be92-4efe4abc470f",
    name: "kaizen",
  },
  {
    id: "5084a3bf-719e-44e1-be92-4efe4abc480f",
    name: "Orders Listing",
  },
  {
    id: "5085a3bf-719e-44e1-be92-4efe4abc481f",
    name: "Orders Report",
  },
  {
    id: "5074a3bf-719e-44e1-be92-4efe4abq1q1q",
    name: "Schedule Email",
  },
  {
    id: "5074a4bf-719e-44e1-be92-4efe4abc471f",
    name: "Contact us",
  },
  {
    id: "bff905e0-4f35-11e9-a194-3798c4448123",
    name: "Categories",
  },
  {
    id: "bff915e0-4f36-12e9-a194-3799c4448123",
    name: "Service Categories",
  },
  {
    id: "bff95401-4f35-11e9-a194-3798c4449c7f",
    name: "Track",
  },
  {
    id: "3f2f9a01-a728-4d5b-8032-f8fd9df75a20",
    name: "Track Repair Feedback",
  },
  {
    id: "4d10c8dc-e59c-461d-8ea8-5d3123d89613",
    name: "Upsell Recommendations",
  },
  {
    id: "bff95403-4f35-11e9-a194-3798c4450c7f",
    name: "Required Documents",
  },
  {
    id: "bff95404-4f35-11e9-a194-3798c4451c7f",
    name: "Settings",
  },
  {
    id: "bff95405-4f35-11e9-a194-3798c4452c7f",
    name: "Content",
  },
  {
    id: "bff95401-4f35-11e9-a194-3798c4448c7f",
    name: "Promo Management",
  },
  {
    id: "bff95401-4f35-11e9-a194-3798d4448c7f",
    name: "Careem Promo",
  },
  {
    id: "bff905e5-4f35-11e9-a194-3798c444qwer",
    name: "Products",
  },
  {
    id: "5085a3bf-719e-44e1-be92-4dfe4abc481f",
    name: "Create Quotation",
  },
  {
    id: "0b806c29-7611-47df-9650-97fe4796bef7",
    name: "Requested Quotations",
  },
  {
    id: "0b806c29-7612-47df-9651-97fe4807bef7",
    name: "Cities",
  },
  { id: "bff92cf5-4f99-11e9-a194-3798c4498c7f", name: "User Activities" },
  {
    id: "bff92cf5-4f99-11e9-a194-3089c4498c7f",
    name: "Collection Appointment",
  },
];

export const ModulesEnum = {
  dashboard: "6b429600-5120-11e9-81fb-19dfb1bf9916",
  // analytics: "bff9540b-4f35-11e9-a194-3798c4448c7f",
  service_provider: "bff92cf5-4f35-11e9-a194-3798c4448c7f",
  branches: "bff92cf5-4f99-11e9-a194-3798c4448c7f",
  variants: "bff92cf5-4f99-11e9-a194-3798c4449c7f",
  orders: "bff92cf5-4f99-11e9-a194-3798c4440c7f",
  cars: "bff95405-4f35-11e9-a194-3798c4448c7f",
  pages: "bff92cf1-4f35-11e9-a194-3798c4448c7f",
  permissions: "bff905e5-4f35-11e9-a194-3798c4448c7f",
  offers: "bff97b13-f112-11e9-a194-3798c4448c7f",
  services: "bff905e0-4f35-11e9-a194-3798c4448c7f",
  subscription_packages: "bff916e0-4f36-11e9-a194-3798c4458c7f",
  subscribed_offers: "bff916e0-4f37-12e1-a195-3799c4458c7f",
  // kaizen: "bff905e0-4f35-11e9-a194-3698c4448c7f",
  // contacts: "bff905e0-4f35-11e9-a194-3798c4448c9f",

  schedule_appointment: "6ea0a0c9-f5d9-45b2-9cee-caf75638df2a",

  estimation_request: "bff97b10-4f35-11e9-a194-3798c4448c7f",

  autohub_web_translation: "bff33c32-4f35-11e9-a194-3798c4448c7f",

  change_password: "bff95409-4f35-11e9-a194-3798c4448c7f",
  knowledge_hub: "bff95409-4f36-11e9-a194-3798c4448c8f",

  career: "qssh95409-4f35-11e9-a194-3798c4448c8h",

  area_of_interest: "vbbh95409-4f35-11e9-a194-3798c4448c9h",

  user: "pchh95409-4f35-11e9-a194-3798c4448c80",

  // location: "bydh95409-4f35-11e9-a194-3798c4448c3m",

  customers: "b0b21a3e-1b29-4ddf-9a8f-e5a9e803353a",

  offers: "bff97b16-4f35-11e9-a194-3798c4448c7f",

  subscriptions: "sff97b16-4f35-11s9-a188-3798c4338c7f",

  kaizen: "5074a3bf-719e-44e1-be92-4efe4abc470f",

  orders_lisitng: "5084a3bf-719e-44e1-be92-4efe4abc480f",
  orders_report_lisitng: "5085a3bf-719e-44e1-be92-4efe4abc481f",

  create_quotation: "5085a3bf-719e-44e1-be92-4dfe4abc481f",
  requested_quotations: "0b806c29-7611-47df-9650-97fe4796bef7",

  cities: "0b806c29-7612-47df-9651-97fe4807bef7",

  blog: "5084a3bf-719e-44e1-be92-4dre4abc480f",
  new_blog: "5084a3bf-719e-44e1-be92-4kls4abc480f",

  schedule_email: "5074a3bf-719e-44e1-be92-4efe4abq1q1q",

  contact_us: "5074a4bf-719e-44e1-be92-4efe4abc471f",

  categories: "bff905e0-4f35-11e9-a194-3798c4448123",

  service_categories: "bff915e0-4f36-12e9-a194-3799c4448123",

  promo_management: "bff95401-4f35-11e9-a194-3798c4448c7f",

  careem_promo: "bff95401-4f35-11e9-a194-3798d4448c7f",

  products: "bff905e5-4f35-11e9-a194-3798c444qwer",
  content: "bff95405-4f35-11e9-a194-3798c4452c7f",
  settings: "bff95404-4f35-11e9-a194-3798c4451c7f",
  required_documents: "bff95403-4f35-11e9-a194-3798c4450c7f",
  track: "bff95401-4f35-11e9-a194-3798c4449c7f",
  track_repair_feedback: "3f2f9a01-a728-4d5b-8032-f8fd9df75a20",
  upsell_recommendation: "4d10c8dc-e59c-461d-8ea8-5d3123d89613",
  user_activities: "bff92cf5-4f99-11e9-a194-3798c4498c7f",
  collection_appointment: "bff92cf5-4f99-11e9-a194-3089c4498c7f",
};
