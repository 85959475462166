import React, { Component, createRef } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import Layout from "../shared/Layout";
import services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import withRouter from "../../util/withRouter";
import InfiniteScrollOverride from "../shared/InfiniteScrollOverride";
import { NotificationManager } from "react-notifications";
import HeaderTitle from "../shared/HeaderTitle";
import TranslationModal from "../shared/TranslationModal";

function TranslationRow({
  localization,
  onEnglishValueChanged,
  onArabicValueChanged,
}) {
  return (
    <tr key={localization._id} className="cases-trow link-row">
      <td style={{ maxWidth: "300px" }}>{localization.stringId}</td>
      {/* <td style={{ maxWidth: "300px" }}>
        {appState.state.language === "ar"
          ? localization.screen.arabicName
          : localization.screen.englishName}
      </td> */}
      <td>
        <textarea
          style={{ minWidth: "300px", maxWidth: "300px", display: "inline" }}
          min="1"
          className="form-control"
          value={localization.value.value_en}
          onChange={(e) => {
            onEnglishValueChanged(localization._id, e.currentTarget.value);
            e.stopPropagation();
          }}
        />
      </td>
      <td>
        <textarea
          style={{ minWidth: "300px", maxWidth: "300px", display: "inline" }}
          min="1"
          className="form-control"
          value={localization.value.value_ar}
          onChange={(e) => {
            onArabicValueChanged(localization._id, e.currentTarget.value);
            e.stopPropagation();
          }}
        />
      </td>
      {/* <td style={{ position: "relative" }}>
        <ActionMenu
          visible={true}
          menuStyle={{
            backgroundColor: "#E9E9E9",
          }}
        >
          <a
            style={{ margin: "10px", cursor: "pointer" }}
            onClick={() => this.deleteHandler(c._id)}
          >
            <span className="fa fa-trash" />
            <FormattedMessage id="global.delete" defaultMessage="Delete" />
          </a>
        </ActionMenu>
      </td> */}
    </tr>
  );
}

export class LocalizationContent extends Component {
  constructor(props) {
    super(props);
    this.searchInput = createRef();
  }

  state = {
    data: [],
    addTranslation: null,
    pageIndex: -1,
    hasMore: true,
    pageSize: 10,
    translation: [],
    searchFilter: "",
    count: 0,
    loading: false,
  };

  async componentDidMount() {
    services.stickyGridHeader();
    this.getCountData(true);
  }

  async loadMore() {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },
      async () => {
        const options = {};
        options.progressBar = true;
        const pageData = await services.getAppLocalization(
          "autohub",
          this.state.searchFilter,
          this.state.pageIndex,
          this.state.pageSize,
          options
        );
        this.setState((currentState) => {
          return {
            translation: currentState.translation.concat(pageData),
            hasMore: pageData.length > 0,
            loading: false,
          };
        });
      }
    );
  }

  async getCountData(summary) {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },
      async () => {
        const options = {};
        options.progressBar = true;
        const { count } = await services.getAppLocalization(
          "autohub",
          this.state.searchFilter,
          this.state.pageIndex,
          this.state.pageSize,
          options,
          false,
          summary
        );
        this.setState(() => {
          return {
            count: count,
            loading: false,
          };
        });
      }
    );
  }

  async exportExcel() {
    const options = {};
    options.progressBar = true;
    try {
      this.setState({ loading: true });
      const response = await services.getAppLocalization(
        "autohub",
        this.state.searchFilter,
        undefined,
        undefined,
        options,
        true
      );
      if (response) {
        this.setState({ loading: false });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  async resetData(e) {
    this.setState({
      pageIndex: -1,
      translation: [],
      hasMore: true,
      searchFilter: this.searchInput.value,
    });
    e.preventDefault();
  }

  async handleSave() {
    try {
      if (!this.state.translation || this.state.translation.length === 0) {
        return;
      }

      if (this.state.translation.filter((c) => c.hasError).length > 0) {
        NotificationManager.warning(
          this.props.intl.formatMessage({
            id: "quantity.pleaseFixAllErrorsBeforeSaving",
            defaultMessage: "Please fix all errors before saving",
          })
        );
        return;
      }

      this.setState({ loading: true });
      const updateRows = this.state.translation.filter(
        (x) => x.updatedValue === true
      );
      await services.updateAppTranslations("autohub", updateRows);

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      console.log("error", e);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      this.setState({ loading: false });
    }
  }

  async resetPage(e) {
    this.setState({
      pageIndex: -1,
      translation: [],
      hasMore: true,
      searchFilter: "",
    });
    this.searchInput.value = "";
    e.preventDefault();
  }

  async handleAddTranslation(values) {
    this.setState({ loading: true });
    try {
      await services.updateAppTranslation("autohub", values);
      this.setState({ addTranslation: null });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { intl } = this.props;
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
          <HeaderTitle
            id="global.translation"
            defaultMessage="AUTOHUB WEB Translation"
          />
          <TranslationModal
            onCancel={() =>
              this.setState({
                addTranslation: null,
              })
            }
            onSubmit={(values) => this.handleAddTranslation(values)}
            show={this.state.addTranslation !== null}
            add={true}
            stringId=""
            loading={this.state.loading}
          />
          <div className="main-container">
            <div
              className="table-header clearfix"
              style={{ marginBottom: "0px" }}
            >
              <form onSubmit={this.resetData.bind(this)}>
                <div className="row">
                  <div className="col-xs-3 pull-right">
                    <div className="panel-group-buttons pull-right">
                      <a
                        className="btn btn-special "
                        onClick={() => {
                          this.setState({
                            addTranslation: "open",
                          });
                        }}
                      >
                        <span className="fa fa-plus" />{" "}
                        <FormattedMessage
                          id="global.addNewTranslation"
                          defaultMessage="New Translation"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-3 pull-right">
                    <div className="panel-group-buttons pull-right">
                      <a
                        className="btn btn-special "
                        onClick={this.handleSave.bind(this)}
                      >
                        <span className="fa fa-floppy-o" />{" "}
                        <FormattedMessage
                          id="global.save"
                          defaultMessage="Save"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-xs-6">
                  <div className="has-feedback">
                    <input
                      type="text"
                      ref={(c) => (this.searchInput = c)}
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "global.search",
                        defaultMessage: "Search",
                      })}
                    />
                    <i className="glyphicon glyphicon-search form-control-feedback" />
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetData.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">search</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.filter"
                        defaultMessage="Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetPage.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-md-12">
                  <FormattedMessage
                    id="global.totalCount"
                    defaultMessage="Total Count"
                  />

                  <strong>
                    {" "}
                    <FormattedNumber
                      style="decimal"
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                      value={this.state.count || 0}
                    />{" "}
                  </strong>
                </div>
              </div>
            </div>
            <div
              id="tableContainer"
              className="dashboard-table-container card-layout"
              style={{ flex: 1, overflowY: "auto", marginBottom: 0 }}
            >
              <div>
                <table className="table dashboard-table table-hover ">
                  {(!this.state.translation ||
                    this.state.translation.length == 0) && (
                    <caption
                      style={{
                        captionSide: "bottom",
                        textAlign: "center",
                        margin: "20px",
                        color: "darkgray",
                      }}
                    >
                      <FormattedMessage
                        id="global.noData"
                        defaultMessage="No data available"
                      />
                    </caption>
                  )}
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage
                          id="category.Id"
                          defaultMessage="Id"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="quantity.editEnglishName"
                          defaultMessage="Edit English Name"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="category.editArabicName"
                          defaultMessage="Edit Arabic Name"
                        />
                      </th>
                    </tr>
                  </thead>
                  <InfiniteScrollOverride
                    element="tbody"
                    className="abcd"
                    pageStart={0}
                    loadMore={this.loadMore.bind(this)}
                    hasMore={this.state.hasMore}
                    useWindow={false}
                    initialLoad={true}
                    // loader={
                    //   <tr key="loader">
                    //     <td colSpan={6}>Loading...</td>
                    //   </tr>
                    // }
                  >
                    {this.state.translation.map((t, i) => (
                      <TranslationRow
                        key={i}
                        localization={t}
                        intl={intl}
                        onEnglishValueChanged={(id, changes) => {
                          // t.value.value_en = changes;
                          this.setState((currentState) => {
                            let data = currentState.translation;

                            const row = data.filter((r) => r._id === id)[0];
                            if (row) {
                              row.value.value_en = changes;
                              row.updatedValue = true;
                            }

                            return { data };
                          });
                        }}
                        onArabicValueChanged={(id, changes) => {
                          // t.value.value_ar = changes;
                          this.setState((currentState) => {
                            let data = currentState.translation;
                            const row = data.filter((r) => r._id === id)[0];
                            if (row) {
                              row.value.value_ar = changes;
                              row.updatedValue = true;
                            }
                            return { data };
                          });
                        }}
                        handleDelete={async (id) => {
                          let filteredArray = this.state.translation.filter(
                            (item) => item._id !== id
                          );
                          this.setState({ translation: filteredArray });
                          await services.deleteAppTranslation("autohub", id);
                        }}
                      />
                    ))}
                  </InfiniteScrollOverride>
                </table>
              </div>
            </div>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(LocalizationContent));
