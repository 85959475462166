import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";
import Layout from "../shared/Layout";
import services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link, Route } from "react-router-dom";
import withRouter from "../../util/withRouter";
import ActionMenu from "../shared/ActionMenu";
import InfiniteScrollOverride from "../shared/InfiniteScrollOverride";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { NotificationManager } from "react-notifications";
import $ from "jquery";
export class AdminList extends Component {
  state = {
    data: [],
    loading: false,
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    searchFilter: "",
    updatingRowId: "",
  };

  getLocalizedName(o) {
    return appState.state.language === "en"
      ? _.get(o, "name.value_en") || _.get(o, "englishName")
      : _.get(o, "name.value_ar") ||
          _.get(o, "arabicName") ||
          _.get(o, "name.value_en") ||
          _.get(o, "englishName");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!this.props.match.params.isFormOpen) {
        if (_.get(this, "props.location.state.isSubmitted") === true) {
          this.setState({
            pageIndex: -1,
            data: [],
            hasMore: true,
            searchFilter: this.searchInput.value,
            disableFlag: true,
          });
        } else {
          this.setState({ updatingRowId: "" });
        }
      }
    }
  }

  async loadMore() {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true
        };
      },
      async () => {
        const options = { progressBar: true, alphabeticalOrder: true };
        const pageData = await services.getRoles(
          this.state.searchFilter,
          this.state.pageIndex,
          this.state.pageSize,
          options
        );
        this.setState((currentState) => {
          return {
            data: currentState.data.concat(pageData),
            hasMore: pageData.length > 0,
            disableFlag: false,
            loading: false
          };
        });
      }
    );
  }

  resetData(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: this.searchInput.value,
      disableFlag: true,
    });
    e.preventDefault();
  }

  resetPage(e) {
    this.setState({
      data: [],
      hasMore: true,
      pageIndex: -1,
      searchFilter: "",

      disableFlag: true,
    });
    this.searchInput.value = "";

    e.preventDefault();
  }

  async handleDelete(role) {
    try {
      await services.deleteRole(role.id);
      this.setState({
        data: [...this.state.data.filter((p) => p.id !== role.id)],
      });
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  }

  render() {
    const { intl } = this.props;
    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Layout hidden={this.props.match.params.isFormOpen} loading={this.state.loading}>
          <HeaderTitle id="roles.roles" defaultMessage="Roles" />
          <div className="main-container">
            <div className="table-header clearfix">
              <form className="row pt-20" onSubmit={this.resetData.bind(this)}>
                <div className="col-xs-3 pull-right">
                  <div className="panel-group-buttons pull-right">
                    <Link className="btn btn-special " to="/role">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="roles.newRole"
                        defaultMessage="Create Role"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <div className="has-feedback">
                    <input
                      type="text"
                      ref={(c) => {
                        this.searchInput = c;
                      }}
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "global.search",
                        defaultMessage: "Search",
                      })}
                    />
                    <i className="glyphicon glyphicon-search form-control-feedback" />
                  </div>
                </div>

                <div className="col">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetData.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">search</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.filter"
                        defaultMessage="Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetPage.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>

            <div className="dashboard-table-container card-layout">
              <div className="table-responsive" style={{ zIndex: "auto" }}>
                <table className="table dashboard-table table-hover ">
                  {(!this.state.data || this.state.data.length == 0) && (
                    <caption
                      style={{
                        captionSide: "bottom",
                        textAlign: "center",
                        margin: "20px",
                        color: "darkgray",
                      }}
                    >
                      <FormattedMessage
                        id="global.noData"
                        defaultMessage="No data available"
                      />
                    </caption>
                  )}
                  <thead>
                    <tr onClick={() => {}}>
                      <th>
                        <FormattedMessage
                          id="roles.name"
                          defaultMessage="Name"
                        />
                      </th>

                      {/* <th>
                      <FormattedMessage
                        id="global.actions"
                        defaultMessage="Actions"
                      />
                    </th> */}
                      <th style={{ width: "20px" }} />
                    </tr>
                  </thead>

                  <InfiniteScrollOverride
                    element="tbody"
                    className="abcd"
                    pageStart={0}
                    loadMore={this.loadMore.bind(this)}
                    hasMore={this.state.hasMore}
                    useWindow={$("body").height() > $(window).height()}
                    initialLoad={true}
                    // loader={
                    //   <tr key="loader">
                    //     <td colSpan={6}>Loading...</td>
                    //   </tr>
                    // }
                  >
                    {this.state.data.map((w) => (
                      <tr
                        key={w.id}
                        onClick={() => {
                          this.setState({ updatingRowId: w.id }, () => {
                            this.props.navigate(`/role/${w.id}`);
                          });
                        }}
                        className="cases-trow link-row"
                      >
                        <td>{w.name}</td>

                        <td style={{ position: "relative" }}>
                          <ActionMenu
                            visible={this.state.visible}
                            menuStyle={{
                              backgroundColor: "#E9E9E9",
                            }}
                          >
                            <Link
                              style={{ margin: "10px", cursor: "pointer" }}
                              to={`/role/${w.id}`}
                            >
                              <span className="fa fa-pencil" />{" "}
                              <FormattedMessage
                                id="global.edit"
                                defaultMessage="Edit"
                              />
                            </Link>
                            <a
                              style={{ margin: "10px", cursor: "pointer" }}
                              onClick={() => {
                                this.handleDelete(w);
                              }}
                            >
                              <span className="fa fa-thumbs-up" />{" "}
                              {this.props.intl.formatMessage({
                                id: "global.delete",
                                defaultMessage: "Delete",
                              })}
                            </a>
                          </ActionMenu>
                        </td>
                        {/* <td className="col-blue">
                      <div className="tooltip-master">
                        <a href="operator-add.html">
                          <i className="material-icons">edit</i>
                        </a>
                        <span className="tooltiptext">Edit</span>
                      </div>
                      <div className="tooltip-master">
                        <a href="javascript:;">
                          <i className="material-icons">launch</i>
                        </a>
                        <span className="tooltiptext">Login As</span>
                      </div>
                    </td> */}
                      </tr>
                    ))}
                  </InfiniteScrollOverride>
                </table>
              </div>
            </div>
          </div>
        </Layout>
        {/* {this.props.match.params.isFormOpen && (
          <Route
            path="/role/:id?"
            component={RoleForm}
            location={{
              pathname: `/role/${this.state.updatingRowId}`,
            }}
          />
        )} */}
      </Auth>
    );
  }
}

export default withRouter(injectIntl(AdminList));
