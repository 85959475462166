import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import Layout from "../shared/Layout";
import Services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import CommentModal from "../shared/CommentModal";
import CommentList from "../shared/CommentList";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import ImageProvider from "../shared/ImageProvider";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { NotificationManager } from "react-notifications";
import { Yup } from "../../util";
import estimationService from "../../services/estimationService";
import Qapter from "../shared/Qapter";
import CancelRequestModal from "../shared/CancelRequestModal";
import QapterHistoryModal from "./qapterHistoryModal";
import { Button } from "react-bootstrap";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export class EstimationRequestForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
    this.state = {
      suggestion: [],
      data: {
        comments: [],
      },
      statusFilter: "all",
      approvalFilter: "all",
      loading: true,
      lighboxSrc: "",
      images: [],
      imageIndex: 0,
      showCommentsList: false,
      addComment: "",
      cancelRequestModal: false,
      cancelRequestReason: "",
      estimationSubmitted: false,
      showQapterModal: false,
    };
  }

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      const { id } = this.props.match.params;
      const options = { progressBar: true, appointment: true };

      if (id) {
        const data = await Services.getScheduleEstimationById(id, options);

        const estimationSubmitted = data.status === "Estimation Completed";

        let images = [
          {
            name: "Vehicle Registration Card",
            src: data.vehicleRegisterationCard,
          },
          { name: "Front", src: data.frontImage },
          { name: "Rear", src: data.rearImage },
          { name: "Right", src: data.rightImage },
          { name: "Left", src: data.leftImage },
          { name: "Insurance", src: data.insurancePdf },
          ...(data.otherImages || []).flatMap((img) => [
            {
              name: "Damaged Area Close Angle (1)",
              src: img.damagedAreaClosedeAngleOne,
            },
            {
              name: "Damaged Area Close Angle (2)",
              src: img.damagedAreaClosedeAngleTwo,
            },
            { name: "Damaged Area Wide Angle", src: img.damagedAreaWideAngle },
          ]),
        ];
        if (data?.userId?.source === "carhub") {
          images = [
            ...images,
            {
              name: "Damaged Area Close Angle (3)",
              src: data?.otherImages[0]?.damagedAreaClosedeAngleThree,
            },
          ];
        }

        this.setState({ data, estimationSubmitted, images });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(
    {
      cost,
      costPdf,
      isApproved,
      immediatePickup,
      howDidItHappen,
      damagedYear,
      whenDidItHappen,
    },
    { setSubmitting }
  ) {
    setSubmitting(true);
    this.setState({ loading: true });
    try {
      let payload = {
        cost: cost,
        costPdf: costPdf,
        isApproved: isApproved,
        immediatePickup: immediatePickup,
        howDidItHappen: howDidItHappen,
        damagedYear: damagedYear,
        whenDidItHappen: whenDidItHappen,
        id: this.props.match.params.id || "",
      };

      if (this.props.match.params.id) {
        await estimationService.updateEstimationRequest(payload);
      }

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      this.props.navigate("/estimation-requests");
    } catch (e) {
      console.log(e?.response?.data);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  async handleSubmitComments({ comment, image }) {
    try {
      if (this.props.match.params.id)
        await Services.addComments({
          comment,
          image,
          role: "admin",
          id: this.props.match.params.id,
        });
      this.setState({ addComment: "" });

      this.setState(
        {
          data: await Services.getScheduleEstimationById(
            this.props.match.params.id
          ),
        },
        () => {
          this.formik.resetForm();
          this.setState({ loading: false });
        }
      );

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      console.log(e?.response?.data);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  }

  async handleSubmitCancelRequest(cancelRequestReason) {
    try {
      const payload = {
        cancelRequestReason: cancelRequestReason,
        cancelRequest: true,
        id: this.props.match.params.id,
      };
      const result = await Services.cancelRequest(payload);
      if (result?.statusCode === 200)
        this.props.navigate("/estimation-requests");

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      console.log(e?.response?.data);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  }

  handleApproval(e) {
    this.formik.setFieldValue("isApproved", e.target.checked);
    if (!e.target.checked) {
      this.formik.setFieldValue("cost", "");
      this.formik.setFieldValue("costPdf", "");
    }
  }

  async viewFile(pdf) {
    const file = await Services.downloadFile(pdf, "view");
    const url = URL.createObjectURL(file);
    window.open(url);
  }

  async fetchImage(url) {
    const fileData = await Services.downloadFile(url, "blob");
    return fileData;
  }

  async createImagesZipFile() {
    const zip = new JSZip();
    const images = [...this.state.images];

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      try {
        const { blob, extension } = await this.fetchImage(image.src);
        const fileName = `${i + 1}. ` + image.name + extension;
        zip.file(fileName, blob);
      } catch (error) {
        console.error("Error fetching images", error);
      }
    }

    zip.generateAsync({ type: "blob" }).then((zipFileBlob) => {
      saveAs(zipFileBlob, "Vehicle Images.zip");
    });
  }

  _renderBody(formikProps) {
    const { isSubmitting, dirty, values } = formikProps;
    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <HeaderTitle
          id="estimations.estimationRequest"
          defaultMessage="Estimation Requests"
        />

        <CommentModal
          show={this.state.addComment != ""}
          onSubmit={this.handleSubmitComments.bind(this)}
          onCancel={() => this.setState({ addComment: "" })}
          formikProps={this.props.formikProps}
          formatMessage={this.props.intl.formatMessage}
          disabled={this.state.estimationSubmitted}
          source={this?.state?.data?.userId?.source}
        />
        <CancelRequestModal
          show={this.state.cancelRequestModal}
          onSubmit={this.handleSubmitCancelRequest.bind(this)}
          onCancel={() =>
            this.setState({
              cancelRequestModal: false,
              cancelRequestReason: "",
            })
          }
          formikProps={this.props.formikProps}
          formatMessage={this.props.intl.formatMessage}
          disabled={this.state.estimationSubmitted}
        />

        <CommentList
          show={this.state.showCommentsList}
          onCancel={() => {
            this.setState({ showCommentsList: false });
          }}
          comments={this.state.data.comments}
        />

        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {this.props.match.params.id && (
                <FormattedMessage
                  id="estimations.requestEstimation"
                  defaultMessage="Estimation Request"
                />
              )}
              <Button
                onClick={(e) => {
                  this.setState({ showCommentsList: true });
                  e.stopPropagation();
                }}
                className={`pull-${
                  appState.state.language === "en" ? "right" : "left"
                }`}
              >
                <span
                  style={{ fontSize: "2rem" }}
                  className="fa fa-comment fa-2"
                />
              </Button>
              {!this.state.data?.cancelRequest && (
                <Button
                  variant="link"
                  onClick={() => this.setState({ addComment: true })}
                  className={`pull-${
                    appState.state.language === "en" ? "right" : "left"
                  }`}
                  style={{ margin: "0 10px" }}
                >
                  <FormattedMessage
                    id="shop.addComment"
                    defaultMessage="Add a comment"
                  />
                </Button>
              )}
            </header>

            <Form>
              <div className="dashboard-row">
                <FieldRow
                  type="text"
                  name="title"
                  labelId="estimations.title"
                  defaultLabel="Title"
                  placeholderId="estimations.enterTitle"
                  defaultPlaceholder="Enter title"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="firstName"
                  labelId="estimations.firstName"
                  defaultLabel="First Name"
                  placeholderId="estimations.enterFirstName"
                  defaultPlaceholder="Enter first name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="lastName"
                  labelId="estimations.lastName"
                  defaultLabel="Last Name"
                  placeholderId="estimations.enterLastName"
                  defaultPlaceholder="Enter last name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="email"
                  labelId="estimations.email"
                  defaultLabel="Email"
                  placeholderId="estimations.enterEmail"
                  defaultPlaceholder="Enter email"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="phoneNumber"
                  labelId="estimations.phoneNumber"
                  defaultLabel="Phone Number"
                  placeholderId="estimations.enterPhoneNumber"
                  defaultPlaceholder="Enter phone number"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="make"
                  labelId="estimations.make"
                  defaultLabel="Make"
                  placeholderId="estimations.enterMake"
                  defaultPlaceholder="Enter make of your product"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="model"
                  labelId="estimations.model"
                  defaultLabel="Model"
                  placeholderId="estimations.enterModel"
                  defaultPlaceholder="Enter model of your product"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                {values?.otherBrand && values?.otherModel ? (
                  <>
                    <FieldRow
                      type="text"
                      name="otherBrand"
                      labelId="estimations.otherBrand"
                      defaultLabel="Other Brand"
                      placeholderId="estimations.otherBrand"
                      defaultPlaceholder="Enter other brand"
                      showRequiredFlag={true}
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="otherModel"
                      labelId="estimations.otherModel"
                      defaultLabel="Other Model"
                      placeholderId="estimations.otherModel"
                      defaultPlaceholder="Enter other model"
                      showRequiredFlag={true}
                      formikProps={formikProps}
                      disabled={true}
                    />
                  </>
                ) : null}
                {values?.otherModel && !values?.otherBrand ? (
                  <>
                    <FieldRow
                      type="text"
                      name="otherModel"
                      labelId="estimations.otherModel"
                      defaultLabel="Other Model"
                      placeholderId="estimations.otherModel"
                      defaultPlaceholder="Enter other model"
                      showRequiredFlag={true}
                      formikProps={formikProps}
                      disabled={true}
                    />
                  </>
                ) : null}
                <FieldRow
                  type="text"
                  name="carNickName"
                  defaultLabel="Car Nick Name"
                  labelId="appointmentCreate.carNickName"
                  placeholderId="appointmentCreate.carNickName"
                  defaultPlaceholder="Enter car nick name"
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="year"
                  labelId="estimations.year"
                  defaultLabel="Year"
                  placeholderId="estimations.year"
                  defaultPlaceholder="Enter year of your product"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <div className="two-fields">
                  <FieldRow
                    type="number"
                    name="plateNumber"
                    defaultLabel="Car Plate Number"
                    labelId="estimations.licencePlate"
                    defaultPlaceholder="5361"
                    placeholderId="estimations.licencePlate"
                    pattern="[0-9]*"
                    showRequiredFlag={true}
                    colSize={4}
                    formikProps={formikProps}
                    disabled={true}
                  />
                  <FieldRow
                    type="text"
                    name="plateChar"
                    defaultPlaceholder="S D F / ق د و"
                    placeholderId="estimations.licencePlate"
                    formikProps={formikProps}
                    disabled={true}
                  />
                </div>
                <FieldRow
                  name="howDidItHappen"
                  type="textarea"
                  labelId="estimations.howDidItHappen"
                  defaultLabel="How Did It Happen"
                  placeholderId="estimations.howDidItHappen"
                  defaultPlaceholder="How Did It Happen"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="whenDidItHappen"
                  labelId="estimations.whenDidItHappen"
                  defaultLabel="When Did It Happen"
                  placeholderId="estimations.whenDidItHappen"
                  defaultPlaceholder="When Did It Happen"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="damagedYear"
                  labelId="estimations.damagedYear"
                  defaultLabel="Damaged Year"
                  placeholderId="estimations.damagedYear"
                  defaultPlaceholder="Damaged Year"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="City"
                  labelId="estimations.city"
                  defaultLabel="City"
                  placeholderId="estimations.city"
                  defaultPlaceholder="City"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="Provider"
                  labelId="estimations.provider"
                  defaultLabel="Provider"
                  placeholderId="estimations.provider"
                  defaultPlaceholder="Provider"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="paymentMethod"
                  labelId="estimations.paymentMethod"
                  defaultLabel="Payment Method"
                  placeholderId="estimations.paymentMethod"
                  defaultPlaceholder="Payment Method"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />

                <div style={{ margin: "20px 0" }}>
                  {this.state.images?.map((img, index) => {
                    if (
                      !(
                        img?.name === "Insurance" ||
                        img?.name === "Front" ||
                        img?.name === "Rear" ||
                        img?.name === "Right" ||
                        img?.name === "Left" ||
                        img?.name === "Vehicle registeration Card"
                      )
                    )
                      return;
                    if (img?.name === "Insurance" && !img?.src) return;
                    return (
                      <>
                        <div className="row" key={index}>
                          <div className={`col-md-2 col-sm-2 col-xs-12`}>
                            <label className="form-label caps block require-flag">
                              <FormattedMessage
                                id={`estimations.${index}`}
                                defaultMessage={img?.name}
                              />
                            </label>
                          </div>
                          <div className="estimation-images-wrapper">
                            <div className="col-md-8 col-sm-8 col-xs-12 estimation-images">
                              <ImageProvider
                                key={index}
                                imageSrc={img?.src}
                                onClick={async () => {
                                  this.setState({
                                    imageIndex: index,
                                    lighboxSrc: img?.src,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div style={{ margin: "20px 0" }}>
                  <div style={{ marginBottom: "20px" }}>
                    <label className="form-label caps block require-flag">
                      <FormattedMessage
                        id="estimation.otherImages"
                        defaultMessage="Other Images"
                      />
                    </label>
                  </div>
                  <div className="row">
                    {this.state.images &&
                      this.state.images?.map((img, index) => {
                        if (
                          img?.name === "Front" ||
                          img?.name === "Rear" ||
                          img?.name === "Right" ||
                          img?.name === "Left" ||
                          img?.name === "Insurance" ||
                          img?.name === "Vehicle registeration Card"
                        )
                          return;
                        return (
                          <>
                            <div className="col-md-4" key={index}>
                              <div className="">
                                <div className=" estimation-images">
                                  <ImageProvider
                                    key={index}
                                    imageSrc={img?.src}
                                    onClick={() => {
                                      this.setState({
                                        imageIndex: index,
                                        lighboxSrc: img?.src,
                                      });
                                    }}
                                  />
                                  <div className="image-description">
                                    <span>
                                      <FormattedMessage
                                        id={`estimation.${index + img?.name}`}
                                        defaultMessage={img?.name}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>

                <Button
                  variant="link"
                  className="mb-4"
                  onClick={this.createImagesZipFile.bind(this)}
                  disabled={isSubmitting}
                >
                  {this.props.intl.formatMessage({
                    id: "estimation.vehicleImages",
                    defaultMessage: "Download Vehicle Images",
                  })}
                </Button>

                <hr />
                <br />

                <h4 style={{ marginBottom: "10px" }}>
                  <FormattedMessage
                    id="estimation.forAdmin"
                    defaultMessage="For Admin"
                  />
                </h4>
                <FieldRow
                  type="checkbox"
                  name="isApproved"
                  labelId="estimation.isApproved"
                  defaultLabel="Approve"
                  formikProps={formikProps}
                  onChange={(e) => this.handleApproval(e)}
                  disabled={
                    this.state.estimationSubmitted ||
                    this.state?.data?.cancelRequest
                  }
                />
                {values?.isApproved && (
                  <>
                    <FieldRow
                      type="number"
                      name="cost"
                      labelId="estimations.cost"
                      defaultLabel="Cost"
                      placeholderId="estimations.cost"
                      defaultPlaceholder="Enter Cost"
                      showRequiredFlag={true}
                      formikProps={formikProps}
                      disabled={this.state.estimationSubmitted}
                    />
                    {!this.state.estimationSubmitted ? (
                      <FieldRow
                        type="file"
                        name="costPdf"
                        labelId="estimations.uploadPDF"
                        defaultLabel="PDF"
                        placeholderId="estimations.uploadPDF"
                        defaultPlaceholder="Estimation PDF"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        legalDocumentation={true}
                        fileicon={false}
                        allowFiles="application/pdf"
                        disabled={this.state.estimationSubmitted}
                      />
                    ) : (
                      <div className="row">
                        <div className="col-md-2 col-sm-2 col-xs-12">
                          <label className="form-label caps block require-flag">
                            <FormattedMessage
                              id="estimations.uploadPDF"
                              defaultMessage="Estimation PDF"
                            />
                          </label>
                        </div>
                        <div
                          className="file-name-display col-md-8 col-sm-8 col-xs-12"
                          onClick={() => this.viewFile(values.costPdf)}
                        >
                          <span>{values.costPdf.split("75973")[1]}</span>
                          <i className="fa fa-eye" />
                        </div>
                      </div>
                    )}
                    {this?.state?.data?.userId?.source === "carhub" && (
                      <FieldRow
                        type="checkbox"
                        name="immediatePickup"
                        labelId="estimation.immediatePickup"
                        defaultLabel="Immediate Pickup"
                        formikProps={formikProps}
                        disabled={
                          this.state.estimationSubmitted ||
                          this.state?.data?.cancelRequest
                        }
                      />
                    )}
                  </>
                )}

                <CheckAccess
                  activityId={ActivitiesEnum.estimation_request_close}
                >
                  {!this.state.data?.isExpired &&
                    !this.state.estimationSubmitted &&
                    !this.state?.data.cancelRequest && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() =>
                          this.setState({ cancelRequestModal: true })
                        }
                      >
                        {this.props.intl.formatMessage({
                          id: "estimation.closeEstimate",
                          defaultMessage: "Close Estimate",
                        })}
                      </button>
                    )}
                </CheckAccess>

                {this.state.data.cancelRequestReason && (
                  <FieldRow
                    type="text"
                    name="cancelRequestReason"
                    defaultLabel="Close Estimation Reason"
                    labelId="appointmentCreate.cancelRequestReason"
                    formikProps={formikProps}
                    disabled={true}
                  />
                )}
              </div>

              <div className="dashboard-row -nobottom d-flex justify-content-between">
                {/* <div className="send-to-qapter-btn-wrapper">
                    {!this.state.data?.cancelRequest && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          this.setState({
                            showQapterModal: true,
                          })
                        }
                      >
                        <FormattedMessage
                          id="global.sendToQapter"
                          defaultMessage="Send to Qapter"
                        />
                      </button>
                    )}

                    {this.state.data.qapterDetails?.length > 0 &&
                      !this.state.data?.cancelRequest && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary viewHistory"
                            onClick={() =>
                              this.setState({
                                showHistoryModal: true,
                              })
                            }
                          >
                            <FormattedMessage
                              id="global.viewHistory"
                              defaultMessage="View History"
                            />
                          </button>
                        </div>
                      )}
                  </div> */}
                <div>
                  {this.props.authContext.authorized && (
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={this.props.intl.formatMessage({
                        id: "global.submit",
                        defaultMessage: "Submit",
                      })}
                      disabled={isSubmitting || !dirty}
                    />
                  )}
                  <Button
                    variant="link"
                    onClick={() => this.props.navigate("/estimation-requests")}
                    disabled={isSubmitting}
                  >
                    {this.props.intl.formatMessage({
                      id: "global.cancel",
                      defaultMessage: "Cancel",
                    })}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {this.state.lighboxSrc && (
            <Lightbox
              imageLoadErrorMessage={
                this.state.lighboxSrc
                  ? "Loading..."
                  : "This image failed to load"
              }
              mainSrc={`/api/util/image?key=${
                this.state.images[this.state.imageIndex].src
              }`}
              nextSrc={
                this.state.images[
                  (this.state.imageIndex + 1) % this.state.images.length
                ]
              }
              prevSrc={
                this.state.images[
                  (this.state.imageIndex + this.state.images.length - 1) %
                    this.state.images.length
                ]
              }
              onMovePrevRequest={() =>
                this.setState({
                  imageIndex:
                    (this.state.imageIndex + this.state.images.length - 1) %
                    this.state.images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  imageIndex:
                    (this.state.imageIndex + 1) % this.state.images.length,
                })
              }
              imageTitle={this.state.images[this.state.imageIndex]["name"]}
              onCloseRequest={() => this.setState({ lighboxSrc: null })}
            />
          )}
        </div>
      </Layout>
    );
  }

  render() {
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            title: this.state.data.title || "",
            firstName: this.state.data.firstName || "",
            lastName: this.state.data.lastName || "",
            email: this.state.data.email || "",
            phoneNumber: this.state.data.phoneNumber || "",
            make:
              appState.state.language === "en"
                ? this.state.data.make?.value_en
                : this.state.data.make?.value_ar || "",
            model:
              appState.state.language === "en"
                ? this.state.data.model?.value_en
                : this.state.data.model?.value_ar || "",
            year: this.state.data.year || "",
            otherBrand: this.state.data.otherBrand || "",
            otherModel: this.state.data.otherModel || "",
            carNickName: this.state.data.carNickName || "",
            plateNumber: this.state.data.licencePlate?.substring(0, 4) || "",
            plateChar: this.state.data.licencePlate?.substring(4) || "",
            howDidItHappen: this.state.data.howDidItHappen || "",
            whenDidItHappen: this.state.data.whenDidItHappen || "",
            damagedYear: this.state.data.damagedYear || "",
            isApproved: this.state.data.isApproved || false,
            immediatePickup: this.state.data.immediatePickup || false,
            cost: this.state.data.cost || "",
            costPdf: this.state.data.costPdf || "",
            cancelRequestReason: this.state.data.cancelRequestReason || "",
            City: this.state.data?.City,
            Provider:
              this.state?.data?.branchId?.name[
                "value_" + appState.state.language
              ],
            paymentMethod: this.state?.data?.paymentMethod,
            insurancePdf: this.state?.data?.insurancePdf,
          }}
          validationSchema={Yup.object().shape(
            {
              cost: Yup.string().required("Cost is required"),
              costPdf: Yup.string().required("PDF is required"),
            },
            ["englishBtnText", "arabicBtnText"]
          )}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>

        <Qapter
          {...{
            show: this.state.showQapterModal,
            intl: this.props.intl,
            onCancel: () =>
              this.setState({
                showQapterModal: false,
              }),
            estimationDetails: this.state.data,
            images: this.state.images,
            props: this.props,
          }}
        />

        <QapterHistoryModal
          {...{
            show: this.state.showHistoryModal,
            onCancel: () =>
              this.setState({
                showHistoryModal: false,
              }),
            estimationDetails: this.state.data,
            props: this.props,
          }}
        />
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(EstimationRequestForm)));
