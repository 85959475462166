import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import Layout from "../shared/Layout";
import AppointmentService from "../../services/appointmentService";
import _ from "lodash";
import Auth from "../shared/Auth";
import withRouter from "../../util/withRouter";
import Table from "../shared/Table";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import ExportToExcel from "../shared/ExportToExcel";
import classNames from "classnames";

const CollectionAppointmentList = (props) => {
  const { intl } = props;

  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [disableFlag, setDisableFlag] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [focusedFrom, setFocusedFrom] = useState(false);
  const [focusedTo, setFocusedTo] = useState(false);

  const searchInputRef = useRef();
  const prevPropsRef = useRef();
  const statusSelectRef = useRef();

  useEffect(() => {
    prevPropsRef.current = props;
  }, []);

  const prevProps = prevPropsRef.current;

  useEffect(() => {
    if (props.location.pathname !== prevProps?.location?.pathname) {
      if (!props.match.params.isFormOpen) {
        if (_.get(props.location, "state.isSubmitted") === true) {
          setPageIndex(-1);
          setData([]);
          setHasMore(true);
          setSearchFilter(searchInputRef.current.value);
          setStatusFilter(statusSelectRef.current.value);
          setDisableFlag(true);
        }
      }
    }
  }, [props.location.pathname]);

  useEffect(() => {
    const subscription = appState.emitter.addListener(
      "onLanguageChange",
      () => {
        setFrom(
          from ? moment(from).locale(appState.state.language) : undefined
        );
        setTo(to ? moment(to).locale(appState.state.language) : undefined);
      }
    );

    return () => {
      subscription.remove();
    };
  }, [props.location.state, from, to]);

  const onExport = async (startDate, endDate) => {
    if (!startDate) return;
    if (!endDate) {
      endDate = startDate;
    }
    setLoading(true);
    try {
      await AppointmentService.downloadCollectionAppointments(
        searchFilter,
        statusFilter,
        moment(startDate)
          .utcOffset(60 * 3)
          .startOf("day")
          .toString(),
        moment(endDate)
          .utcOffset(60 * 3)
          .endOf("day")
          .toString()
      );
    } catch (err) {}
    setLoading(false);
  };

  const loadMore = async () => {
    setLoading(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
      fromDate: from ? moment(from).startOf("day").toString() : null,
      toDate: to ? moment(to).endOf("day").toString() : null,
    };

    const pageData = await AppointmentService.getCollectionAppointments({
      searchFilter,
      statusFilter,
      pageIndex: _pageIndex,
      pageSize: 10,
      fromDate: from ? moment(from).startOf("day").toString() : null,
      toDate: to ? moment(to).endOf("day").toString() : null,
      options,
    });
    let summary = { count };
    if (_pageIndex === 0) {
      summary = await AppointmentService.getCollectionAppointments({
        searchFilter,
        statusFilter,
        fromDate: from ? moment(from).startOf("day").toString() : null,
        fromDate: to ? moment(to).endOf("day").toString() : null,
        options,
        summary,
      });
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setDisableFlag(false);
    setLoading(false);
    if (_pageIndex === 0) {
      setCount(summary?.count);
    }
  };

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter(searchInputRef.current.value);
    setStatusFilter(statusSelectRef.current.value);
    setDisableFlag(true);
    if (e) e.preventDefault();
  };

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter("");
    setStatusFilter("all");
    setFrom("");
    setTo("");

    searchInputRef.current.value = "";
    statusSelectRef.current.value = "all";
    e.preventDefault();
  };

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Layout hidden={props.match.params.isFormOpen} loading={loading}>
        <HeaderTitle
          id="collectionAppointment.collectionappointmentRequest"
          defaultMessage="Collection Appointment Requests"
        />
        <div className="table-header clearfix">
          <form className="row" onSubmit={resetData}>
            <div className="col-xs-3 pull-right"></div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="has-feedback">
                <input
                  type="text"
                  ref={searchInputRef}
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: "global.search",
                    defaultMessage: "Search",
                  })}
                />
                <i className="glyphicon glyphicon-search form-control-feedback" />
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
              <SingleDatePicker
                id="fromDate"
                placeholder={intl.formatMessage({
                  id: "global.from",
                  defaultMessage: "From",
                })}
                block={true}
                small={true}
                isOutsideRange={() => false}
                numberOfMonths={1}
                initialDate={from}
                isRTL={appState.state.language === "ar" ? true : false}
                date={from}
                onDateChange={(date) => {
                  setFrom(date);
                  resetData();
                }}
                focused={focusedFrom}
                onFocusChange={({ focused }) => setFocusedFrom(focused)}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
              <SingleDatePicker
                id="toDate"
                block={true}
                placeholder={intl.formatMessage({
                  id: "global.to",
                  defaultMessage: "To",
                })}
                small={true}
                isOutsideRange={() => false}
                numberOfMonths={1}
                initialDate={to}
                isRTL={appState.state.language === "ar" ? true : false}
                date={to}
                onDateChange={(date) => {
                  setTo(date);
                  resetData();
                }}
                focused={focusedTo}
                onFocusChange={({ focused }) => setFocusedTo(focused)}
              />
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <select ref={statusSelectRef} className="form-control">
                <option value="all">
                  {intl.formatMessage({
                    id: "global.status",
                    defaultMessage: "Status",
                  })}
                </option>
                <option value="collected">
                  {intl.formatMessage({
                    id: "collectionAppointment.collectedStatus",
                    defaultMessage: "Collected",
                  })}
                </option>
                <option value="not-collected">
                  {intl.formatMessage({
                    id: "collectionAppointment.notCollectedStatus",
                    defaultMessage: "Not collected",
                  })}
                </option>
              </select>
            </div>
            <div className="col force--inline">
              <div className="tooltip-master">
                <button
                  href="javascript:;"
                  className="btn btn-primary"
                  onClick={resetData}
                  disabled={disableFlag}
                >
                  <i className="material-icons">search</i>
                </button>
                <span className="tooltiptext">
                  <FormattedMessage
                    id="global.filter"
                    defaultMessage="Filter"
                  />
                </span>
              </div>
            </div>
            <div className="col force--inline">
              <div className="tooltip-master">
                <button
                  href="javascript:;"
                  className="btn btn-primary"
                  onClick={resetPage}
                  disabled={disableFlag}
                >
                  <i className="material-icons">settings_backup_restore</i>
                </button>
                <span className="tooltiptext">
                  <FormattedMessage
                    id="global.resetFilter"
                    defaultMessage="Reset Filter"
                  />
                </span>
              </div>
            </div>
            <div className="col-xs-3 pull-right">
              <div className="panel-group-buttons pull-right">
                <CheckAccess
                  activityId={
                    ActivitiesEnum.collectionAppointment_request_export_to_excel
                  }
                >
                  <ExportToExcel onExport={onExport} />
                </CheckAccess>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col-md-12">
              <FormattedMessage
                id="global.totalCount"
                defaultMessage="Total Count"
              />
              <strong>
                {" "}
                <FormattedNumber
                  style="decimal"
                  maximumFractionDigits={0}
                  minimumFractionDigits={0}
                  value={count || 0}
                />
              </strong>
            </div>
          </div>
        </div>

        <CheckAccess
          activityId={ActivitiesEnum.collectionAppointment_request_all}
        >
          <div className="dashboard-table-container card-layout">
            <div className="table-responsive">
              <Table
                loadMore={loadMore}
                className="abc"
                hasMore={hasMore}
                caption={
                  (!data || data == 0) && (
                    <caption
                      style={{
                        captionSide: "bottom",
                        textAlign: "center",
                        margin: "20px",
                        color: "darkgray",
                      }}
                    >
                      <FormattedMessage
                        id="global.noData"
                        defaultMessage="No data available"
                      />
                    </caption>
                  )
                }
                headerRow={
                  <tr>
                    <th>
                      <FormattedMessage
                        id="collectionAppointment.hashId"
                        defaultMessage="Reference Id"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="collectionAppointment.createdAt"
                        defaultMessage="Created At"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="collectionAppointment.appointmentHash"
                        defaultMessage="Appointment Hash"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="collectionAppointment.firstName"
                        defaultMessage="First Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="collectionAppointment.lastName"
                        defaultMessage="Last Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        defaultMessage="Phone Number"
                        id="collectionAppointment.phoneNumber"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        defaultMessage="Car Number Plate"
                        id="collectionAppointment.carNumberPlate"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        defaultMessage="Branch Name"
                        id="collectionAppointment.branchName"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        defaultMessage="Car Delivery Time"
                        id="collectionAppointment.deliveryTime"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        defaultMessage="Service"
                        id="collectionAppointment.service"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        defaultMessage="Status"
                        id="collectionAppointment.status"
                      />
                    </th>
                    <th style={{ width: "20px" }} />
                  </tr>
                }
              >
                {data &&
                  data?.map((d) => (
                    <tr
                      key={d._id}
                      onClick={() =>
                        props.navigate(`/collection-appointment/${d._id}`)
                      }
                      className="cases-trow link-row"
                    >
                      <td>{d.collectionHashId}</td>
                      <td>{moment(d?.createdAt).utc().format("YYYY-MM-DD")}</td>
                      <td>{d?.appointmentHash || "-"}</td>
                      <td>{d?.firstName}</td>
                      <td>{d?.lastName}</td>
                      <td className="phoneNumber-alignment">
                        {d?.phoneNumber}
                      </td>
                      <td>{d?.carNumberPlate?.toUpperCase()}</td>
                      <td>
                        {
                          d?.branch?.name?.[
                            `value_${appState?.state?.language}`
                          ]
                        }
                      </td>
                      <td>
                        {" "}
                        {moment(d?.carCollectionAt?.date).format(
                          "YYYY-MM-DD"
                        )}{" "}
                        {d?.carCollectionAt?.slot}
                      </td>
                      <td>
                        {d?.services?.[0]?.name?.[
                          `value_${appState?.state?.language}`
                        ] || "-"}
                      </td>
                      <td
                        className={classNames({
                          "col-status-green": d.isCollected,
                          "col-status-red": !d.isCollected,
                        })}
                      >
                        <a href="javascript:;">
                          {d.isCollected && (
                            <FormattedMessage
                              id="collectionAppointment.collectedStatus"
                              defaultMessage="Collected"
                            />
                          )}
                          {!d.isCollected && (
                            <FormattedMessage
                              id="collectionAppointment.notCollectedStatus"
                              defaultMessage="Not collected"
                            />
                          )}
                        </a>
                      </td>
                    </tr>
                  ))}
              </Table>
            </div>
          </div>
        </CheckAccess>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(CollectionAppointmentList));
