import React, { useState } from "react";
import { Step, StepTitle, Stepper } from "react-progress-stepper";
import { Badge } from "react-bootstrap";
import appState from "../../../state/AppStateContainer";
import { FormattedMessage } from "react-intl";
import {
  estimationStatus,
  getFormattedCarNumberPlate,
} from "../../../util/utils";
import EstimationNotificationModal from "./EstimationNotificationModal";
import services from "../../../services";
import EstimationCancelReasonModal from "./EstimationCancelReasonModal";

const EstimateProgress = ({ estimations, loader, setLoading }) => {
  const language = appState.state.language;

  const [selectedEstimation, setSelectedEstimation] = useState({});
  const [notificationModalViewer, setNotificationModalViewer] = useState(false);
  const [reasonModalViewer, setReasonModalViewer] = useState(false);

  const createMasterStep = (masterStatus) => {
    const status = estimationStatus[masterStatus];

    const step1Props = { customContent: undefined };
    if (status === 0) {
      step1Props.customContent = () => <div className="current-step" />;
    }

    const step2Props = { customContent: undefined };
    if (status === 1) {
      step2Props.customContent = () => <div className="current-step" />;
    }

    return (
      <Stepper step={status} numbered={false}>
        <Step {...step1Props}>
          <StepTitle>
            <FormattedMessage
              id="track.estimationRequested"
              defaultMessage="Estimation Requested"
            />
          </StepTitle>
        </Step>
        <Step {...step2Props}>
          <StepTitle>
            <FormattedMessage
              id="track.estimationInProgress"
              defaultMessage="Estimation In Progress"
            />
          </StepTitle>
        </Step>
        <Step customContent={() => <i className="fa fa-trophy" />}>
          <StepTitle>
            {masterStatus === "Estimation Closed" ? (
              <FormattedMessage
                id="track.estimateClosed"
                defaultMessage="Estimation Closed"
              />
            ) : (
              <FormattedMessage
                id="track.estimateCompleted"
                defaultMessage="Estimation Completed"
              />
            )}
          </StepTitle>
        </Step>
      </Stepper>
    );
  };

  const viewComments = (est) => {
    try {
      setSelectedEstimation(est);
      setNotificationModalViewer(true);
    } catch (error) {
      console.log(error);
    }
  };

  const closeNotificationModal = () => setNotificationModalViewer(false);

  const getUnseenComments = (comments) =>
    comments?.filter((comment) => !comment?.seen).length || 0;

  const handleViewReasonModal = (est) => {
    setSelectedEstimation(est);
    setReasonModalViewer(true);
  };

  const closeReasonModal = () => {
    setReasonModalViewer(false);
    setSelectedEstimation({});
  };

  const downloadPDF = async (file) => {
    setLoading(true);
    await services.downloadFile(file);
    setLoading(false);
  };
  return (
    <div className="progress-tracker">
      {estimations.length ? (
        estimations.map((est, i) => {
          let parentClass = "row request-estimate-body estimate-head ";
          parentClass += est?.isApproved ? " estimation-approved" : "";
          parentClass += est?.isExpired ? " estimation-expired" : "";
          parentClass += est?.cancelRequest ? " estimation-canceled" : "";
          parentClass += est?.isAppointmentCreated ? " estimation-booked" : "";

          const car = est.carData[0];

          return (
            <div className={parentClass} key={i}>
              <div className="col-md-3 col-lg-3 col-xl-2 status-for">
                <h5>
                  {est.otherBrand || car?.brand["value_" + language]}{" "}
                  {est.otherModel || car?.product["value_" + language]}
                  <br />
                  {getFormattedCarNumberPlate(est.licencePlate)}
                </h5>
                {window.innerWidth <= 991 && est?.isApproved && (
                  <div className="estimation-data">
                    <span className="moreDetails">
                      <FormattedMessage
                        id="track.cost"
                        defaultMessage={"Cost"}
                      />{" "}
                      :{" "}
                      <span className="fw-bold">
                        {est?.cost}{" "}
                        <FormattedMessage
                          id="track.SAR"
                          defaultMessage={"SAR"}
                        />
                      </span>
                    </span>
                    <a className="pdf-link">
                      <button onClick={() => downloadPDF(est?.costPdf)}>
                        <FormattedMessage
                          id="track.downloadEstimation"
                          defaultMessage={"Download Estimation"}
                        />
                      </button>
                    </a>

                    {est?.status !== "Estimation Completed" && (
                      <div className="estimation-btn">
                        <FormattedMessage
                          id={
                            est?.isAppointmentCreated
                              ? "track.booked"
                              : "track.notBooked"
                          }
                          defaultMessage={
                            est?.isAppointmentCreated ? "Booked" : "Not Booked"
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
                {est?.isExpired && !est?.cancelRequest && (
                  <p className="expired-label">
                    <FormattedMessage
                      id="track.estimationExpired"
                      defaultMessage={"Estimation Expired"}
                    />
                  </p>
                )}

                {est?.cancelRequest && (
                  <button
                    className="view-reason"
                    onClick={() => handleViewReasonModal(est)}
                  >
                    <FormattedMessage
                      id="track.viewReason"
                      defaultMessage="View Reason"
                    />
                  </button>
                )}
              </div>
              <div className="col-md-8 col-lg-7 col-xl-8">
                {createMasterStep(est?.status)}
              </div>
              <div className="col-md-1 col-lg-2 col-xl-2 p-0">
                <div className="notification-icon">
                  <i className="fa fa-bell" onClick={() => viewComments(est)} />
                  <Badge className="badge-icon" bg="warning">
                    {getUnseenComments(est?.comments)}
                  </Badge>
                </div>
                {window.innerWidth > 991 && est?.isApproved && (
                  <div className="estimation-data desktop">
                    <span className="moreDetails">
                      <FormattedMessage
                        id="track.cost"
                        defaultMessage={"Cost"}
                      />
                      :{" "}
                      <span className="fw-bold">
                        {est?.cost}{" "}
                        <FormattedMessage
                          id="track.SAR"
                          defaultMessage={"SAR"}
                        />
                      </span>
                    </span>
                    <a
                      className="pdf-link"
                      onClick={() => downloadPDF(est?.costPdf)}
                    >
                      <button>
                        <FormattedMessage
                          id="track.downloadEstimation"
                          defaultMessage={"Download Estimation"}
                        />
                      </button>
                    </a>

                    {est?.status !== "Estimation Completed" && (
                      <div className="estimation-btn">
                        {!est?.isAppointmentCreated ? (
                          <span>
                            <FormattedMessage
                              id="track.notBooked"
                              defaultMessage="Not Booked"
                            />
                          </span>
                        ) : (
                          <span>
                            <FormattedMessage
                              id="track.booked"
                              defaultMessage="Booked"
                            />
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })
      ) : !estimations.length && !loader ? (
        <h5 className="no-data">
          <FormattedMessage
            id="track.noEstimation"
            defaultMessage="No Estimation"
          />
        </h5>
      ) : null}

      <EstimationNotificationModal
        show={notificationModalViewer}
        close={closeNotificationModal}
        selectedEstimation={selectedEstimation}
      />

      {reasonModalViewer && (
        <EstimationCancelReasonModal
          show={reasonModalViewer}
          close={closeReasonModal}
          selectedEstimation={selectedEstimation}
        />
      )}
    </div>
  );
};

export default EstimateProgress;
