import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { FormattedMessage, injectIntl } from "react-intl";
import withRouter from "../../util/withRouter";
import * as Yup from "yup";
import Layout from "../shared/Layout";
import { NotificationManager } from "react-notifications";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import FieldRow from "../shared/FieldRow";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import SettingsService from "../../services/settingsService";
import _ from "lodash";

const MoreSettingsForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    try {
      setLoading(true);
      const setting = await SettingsService.getSettings();
      setData(setting);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const settingsSchema = () => {
    return Yup.object().shape({
      otpAttempts: Yup.number()
        .notOneOf(
          [0],
          props.intl.formatMessage({
            id: "error.notAllowed",
            defaultMessage: "Input must not be zero",
          })
        )
        .required(
          props.intl.formatMessage({
            id: "error.required",
            defaultMessage: "Required",
          })
        ),
      blockTime: Yup.number()
        .notOneOf(
          [0],
          props.intl.formatMessage({
            id: "error.notAllowed",
            defaultMessage: "Input must not be zero",
          })
        )
        .required(
          props.intl.formatMessage({
            id: "error.required",
            defaultMessage: "Required",
          })
        ),
      thresholdTime: Yup.number()
        .notOneOf(
          [0],
          props.intl.formatMessage({
            id: "error.notAllowed",
            defaultMessage: "Input must not be zero",
          })
        )
        .required(
          props.intl.formatMessage({
            id: "error.required",
            defaultMessage: "Required",
          })
        ),
      cadRadius: Yup.number()
        .notOneOf(
          [0],
          props.intl.formatMessage({
            id: "error.notAllowed",
            defaultMessage: "Input must not be zero",
          })
        )
        .required(
          props.intl.formatMessage({
            id: "error.required",
            defaultMessage: "Required",
          })
        ),
      cadPrice: Yup.number()
        .min(
          1,
          props.intl.formatMessage({
            id: "error.notAllowed",
            defaultMessage: "Input must be > 0",
          })
        )
        .required(
          props.intl.formatMessage({
            id: "error.required",
            defaultMessage: "Required",
          })
        ),
      appointmentNotes_en: Yup.string().label(
        props.intl.formatMessage({
          id: "error.appointmentNotesEn",
          defaultMessage: "Appointment Notes (English)",
        })
      ),
      appointmentNotes_ar: Yup.string().label(
        props.intl.formatMessage({
          id: "error.appointmentNotesAr",
          defaultMessage: "Appointment Notes (Arabic)",
        })
      ),
    });
  };

  const saveSettings = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      setSubmitting(true);

      const response = await SettingsService.updateSettings(values);
      if (response?.message === "settings.updatedSuccessfully")
        NotificationManager.success(
          props.intl.formatMessage({
            id: "settings.updatedSuccessfully",
            defaultMessage: "Settings updated successfully",
          }),
          props.intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );
    } catch (err) {
      console.log(err);
      NotificationManager.error(
        props.intl.formatMessage({
          id: _.get(err, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        otpAttempts: data?.otpAttempts || "",
        blockTime: data?.blockTime || "",
        thresholdTime: data?.thresholdTime || "",
        cadRadius: data?.cadRadius || "",
        cadPrice: data?.cadPrice || "",
        appointmentNotes_en: data?.appointmentNotes?.value_en || "",
        appointmentNotes_ar: data?.appointmentNotes?.value_ar || "",
      }}
      validationSchema={settingsSchema}
      onSubmit={saveSettings}
    >
      {(formikProps) => {
        const { isSubmitting, dirty } = formikProps;
        return (
          <Layout noLayout={props.noLayout} loading={loading}>
            <HeaderTitle id="settings.settings" defaultMessage="Settings" />
            <div className="dashboard-table-container card-layout">
              <div className="panel-primary">
                <header className="panel-title">
                  <FormattedMessage
                    id="settings.settings"
                    defaultMessage="Settings"
                  />
                </header>

                <div className="">
                  <Form>
                    <div className="dashboard-row">
                      <FieldRow
                        type="number"
                        name="otpAttempts"
                        showRequiredFlag={true}
                        defaultLabel="Number Of OTP Attempts"
                        labelId="settings.otpAttempts"
                        placeholderId="settings.otpAttempts"
                        defaultPlaceholder="Number Of OTP Attempts"
                        formikProps={formikProps}
                      />
                      <FieldRow
                        type="number"
                        name="thresholdTime"
                        showRequiredFlag={true}
                        defaultLabel="Threshold Time"
                        labelId="settings.thresholdTime"
                        placeholderId="settings.thresholdTime"
                        defaultPlaceholder="Threshold Time"
                        formikProps={formikProps}
                      />
                      <FieldRow
                        type="number"
                        name="blockTime"
                        showRequiredFlag={true}
                        defaultLabel="Block Time In Minutes"
                        labelId="settings.blockTime"
                        placeholderId="settings.blockTime"
                        defaultPlaceholder="Block Time In Minutes"
                        formikProps={formikProps}
                      />
                      <FieldRow
                        type="number"
                        name="cadRadius"
                        showRequiredFlag={true}
                        defaultLabel="Cash and Delivery Radius (Km)"
                        labelId="settings.cadRadius"
                        placeholderId="settings.cadRadius"
                        defaultPlaceholder="Cash and Delivery Radius (Km)"
                        formikProps={formikProps}
                      />
                      <FieldRow
                        type="number"
                        name="cadPrice"
                        showRequiredFlag={true}
                        defaultLabel="Cash and Delivery Price"
                        labelId="settings.cadPrice"
                        placeholderId="settings.cadPrice"
                        defaultPlaceholder="Cash and Delivery Price"
                        formikProps={formikProps}
                      />
                      <FieldRow
                        type="html"
                        name="appointmentNotes_en"
                        labelId="settings.appointmentNotesEn"
                        defaultLabel="Appointment Notes (English)"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        textEditor={true}
                      />
                      <FieldRow
                        type="html"
                        name="appointmentNotes_ar"
                        labelId="settings.appointmentNotesAr"
                        defaultLabel="Appointment Notes (Arabic)"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        textEditor={true}
                      />
                    </div>
                    <div className="dashboard-row -nobottom">
                      <CheckAccess activityId={ActivitiesEnum.settings_update}>
                        {props.authContext.authorized && (
                          <input
                            type="submit"
                            className="btn btn-primary"
                            value={props.intl.formatMessage({
                              id: "global.save",
                              defaultMessage: "Save",
                            })}
                            disabled={isSubmitting || !dirty}
                          />
                        )}
                      </CheckAccess>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Layout>
        );
      }}
    </Formik>
  );
};

export default React.memo(
  withAuthContext(withRouter(injectIntl(MoreSettingsForm)))
);
