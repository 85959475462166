import React, { useRef, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import services from "../../services";
import appState from "../../state/AppStateContainer";

const OtpVerificationModal = (props) => {
  const { intl } = props;

  const formik = useRef();

  const [showOtp, setShowOtp] = useState(false);

  const verificationHandler = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      let token = await services.verificationOtp({
        ...values,
        username: props?.values?.username,
      });
      NotificationManager.success(
        intl.formatMessage({
          id: "login.verificationsuccessful",
          defaultMessage: "Verification Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      appState.setToken(token);
    } catch (e) {
      NotificationManager.error(
        intl.formatMessage({
          id: e?.response?.data?.response || "error.unexpectedError",
          defaultMessage: "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal show={props.show} centered>
      <Modal.Header closeButton={false}>
        <h4>
          <FormattedMessage
            id="login.verification"
            defaultMessage="Verification"
          />
        </h4>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formik}
          validationSchema={Yup.object().shape({
            otp: Yup.string()
              .label(
                intl.formatMessage({
                  id: "global.otp",
                  defaultMessage: "OTP",
                })
              )
              .trim()
              .required(),
          })}
          onSubmit={verificationHandler}
          initialValues={{
            otp: props.otp || "",
          }}
        >
          {({ values, handleChange, touched, errors, isSubmitting }) => {
            return (
              <Form>
                {props.add && (
                  <div className="admin-change-password-wrapper">
                    <FormattedMessage
                      id="global.enterOtp"
                      defaultMessage="Enter OTP"
                    />
                    <input
                      name="otp"
                      type={showOtp ? "text" : "password"}
                      autoComplete="off"
                      value={values.otp}
                      onChange={handleChange}
                      className="form-control"
                    />
                    <span
                      className="input-group-btn admin-emoji-floater admin-change-password"
                      style={{ paddingRight: "129px" }}
                    >
                      <a
                        onClick={() => {
                          setShowOtp(!showOtp);
                        }}
                        className="btn btn-default"
                      >
                        <i className="fa fa-eye" />
                      </a>
                    </span>
                    {touched["otp"] && errors["otp"] && (
                      <p className="text-warning">{errors["otp"]}</p>
                    )}
                  </div>
                )}
                <br />

                <div className="modal-footer" style={{ padding: "15px 0 0" }}>
                  <Button
                    variant="link"
                    onClick={() => {
                      props.onOtpResend && props.onOtpResend();
                    }}
                    disabled={isSubmitting}
                  >
                    <FormattedMessage
                      id="global.resend"
                      defaultMessage="Resend"
                    />
                  </Button>
                  <input
                    type="submit"
                    value={intl.formatMessage({
                      id: "global.verify",
                      defaultMessage: "Verify",
                    })}
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default injectIntl(OtpVerificationModal);
