import React, { useState, useRef, useEffect } from "react";
import { Form, Field, Formik } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { FormattedMessage, injectIntl } from "react-intl";
import services from "../../services";
import appState from "../../state/AppStateContainer";
import _ from "lodash";
import withRouter from "../../util/withRouter";
import OtpVerificationModel from "../shared/OtpVerificationModel";
import "../serviceAdvisorLogin/style.css";
import Error from "../../util/errorMessage";

const Login = (props) => {
  const { intl, navigate, location } = props;
  const lang = appState.state.language;

  const formikRef = useRef();
  // const dialog = useRef();

  const [showPassword, setShowPassword] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);

  useEffect(() => {
    formikRef.current.validateForm();
  }, [lang]);

  const validationSchema = () => {
    return Yup.object().shape({
      username: Yup.string().required(
        intl.formatMessage({
          id: "global.required",
          defaultMessage: "Required",
        })
      ),
      password: Yup.string().required(
        intl.formatMessage({
          id: "global.required",
          defaultMessage: "Required",
        })
      ),
    });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      let token = await services.login(
        values.username,
        values.password,
        appState.state.simpleMode,
        appState.state.currentLongitude,
        appState.state.currentLatitude
      );

      // if (!token?.locationMatched && appState.state.simpleMode) {
      //   dialog.show({
      //     body: intl.formatMessage({
      //       id: "login.areYouSure",
      //       defaultMessage:
      //         "Location did not match are you sure you want to proceed ?",
      //     }),
      //     actions: [
      //       Dialog.CancelAction(),
      //       Dialog.DefaultAction(
      //         intl.formatMessage({
      //           id: "global.proceed",
      //           defaultMessage: "continue",
      //         }),
      //         async () => {
      //           NotificationManager.success(
      //             intl.formatMessage({
      //               id: "global.loginSuccessful",
      //               defaultMessage: "Login Successful",
      //             }),
      //             intl.formatMessage({
      //               id: "global.success",
      //               defaultMessage: "Success",
      //             })
      //           );
      //           if (_.get(this, "location.state.from")) {
      //             token = {
      //               ...token,
      //               route: _.get(this, "location.state.from"),
      //             };
      //             location.state.from = undefined;
      //           }
      //           appState.setToken(token);
      //         },
      //         "btn-custom"
      //       ),
      //     ],
      //   });
      // }

      if (token?.statusCode === 200) {
        if (values.username === "riskcrew") {
          verificationHandler({ otp: "29293" });
        } else {
          if (token?.response?.isSubAdmin) {
            NotificationManager.success(
              intl.formatMessage({
                id: "global.loginSuccessful",
                defaultMessage: "Login Successful",
              }),
              intl.formatMessage({
                id: "global.success",
                defaultMessage: "Success",
              })
            );
            if (_.get(this, "location.state.from")) {
              token = {
                ...(token?.response || token),
                route: _.get(this, "location.state.from"),
              };
              location.state.from = undefined;
            }

            appState.setToken(token?.response || token);
          } else {
            NotificationManager.success(
              intl.formatMessage({
                id: "auth.otpSentSuccessfully",
                defaultMessage: "Otp sent Successful",
              }),
              intl.formatMessage({
                id: "global.success",
                defaultMessage: "Success",
              })
            );
            setVerifyOtp(true);
          }
        }
      } else {
      }
    } catch (e) {
      NotificationManager.error(
        intl.formatMessage({
          id:
            e?.response?.data?.response ||
            Error[e?.response?.data?.key]?.key ||
            "error.unexpectedError",
          defaultMessage:
            Error[e?.response?.data?.key]?.vlaue ||
            "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div
        className={`${lang === "en" ? "en-bg-sky-white" : "ar-bg-sky-white"}`}
      >
        <div className="lang-wrapper">
          <a
            className=""
            style={{ cursor: "pointer" }}
            onClick={() => {
              appState.changeLanguage();
            }}
          >
            <span className="fa fa-language fa-2x" />
          </a>
        </div>

        <div className="login-row-wrapper">
          <div className="service-advisor-login-wrapper">
            <div className="login-left-box">
              <h3>
                <FormattedMessage
                  id="login.adminPanel"
                  defaultMessage="Admin Panel"
                />
              </h3>
            </div>
            <div className="right-form-box">
              <Formik
                initialValues={{
                  username: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                innerRef={formikRef}
                onSubmit={handleSubmit}
              >
                {({ touched, errors, isSubmitting }) => {
                  return (
                    <Form>
                      <div>
                        <div className="autohub-logo-wrapper">
                          <img
                            src={`/design/images/${
                              lang === "en" ? "ah-logo.webp" : "ah-logo-ar.webp"
                            }`}
                            alt="The Auto Hub CMS"
                          />
                        </div>
                        <div className="full-row">
                          <Field
                            type="text"
                            className={`service-advisor-login-field ${
                              lang === "ar" ? "arabic-field" : ""
                            }`}
                            name="username"
                            placeholder={intl.formatMessage({
                              id: "login.enterUsername",
                              defaultMessage: "Enter your username",
                            })}
                            disabled={isSubmitting}
                          />
                          {touched.username && errors.username && (
                            <p className="text-warning">{errors.username}</p>
                          )}
                        </div>
                        <div className="full-row">
                          <div
                            className="emoji-wrapper"
                            style={{ paddingTop: "5px" }}
                          >
                            <Field
                              type={showPassword ? "text" : "password"}
                              className={`service-advisor-login-field ${
                                lang === "ar" ? "arabic-field" : ""
                              }`}
                              name="password"
                              placeholder={intl.formatMessage({
                                id: "login.enterPassword",
                                defaultMessage: "Enter password",
                              })}
                              disabled={isSubmitting}
                            />
                            <span
                              className="input-group-btn emoji-floater"
                              style={{
                                paddingRight: "14px",
                                paddingLeft: "14px",
                              }}
                            >
                              <a
                                onClick={() => setShowPassword(!showPassword)}
                                className="btn btn-default"
                              >
                                <i
                                  className={`fa ${
                                    !showPassword ? "fa-eye" : "fa-eye-slash"
                                  }`}
                                />
                              </a>
                            </span>
                            {touched.password && errors.password && (
                              <p className="text-warning">{errors.password}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="full-row">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/forgot-password`)}
                        >
                          <FormattedMessage
                            id="login.forgotPassword"
                            defaultMessage="Forgot Password?"
                          />
                        </a>
                        {/* <Dialog
                          ref={dialog}
                          defaultCancelLabel={intl.formatMessage({
                            id: "global.cancel",
                            defaultMessage: "Cancel",
                          })}
                        /> */}
                      </div>

                      <div className="full-row login-btn-wrapper">
                        <input
                          type="submit"
                          value={intl.formatMessage({
                            id: "global.login",
                            defaultMessage: "Login",
                          })}
                          className="btn btn-primary btn-login"
                          disabled={isSubmitting}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <OtpVerificationModel
        onOtpResend={() => {
          formikRef.current.submitForm();
          setVerifyOtp(false);
        }}
        values={formikRef?.current?.values}
        show={verifyOtp}
        add={true}
      />
    </>
  );
};

export default withRouter(injectIntl(Login));
