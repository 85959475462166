import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import FieldRow from "../../shared/FieldRow";
import classNames from "classnames";
import { Accordion } from "react-bootstrap";

class InvoiceDetails extends Component {
  constructor() {
    super();
  }

  state = {
    InvoiceDetailsError: false,
    InvoiceDetailsExpanded: false,
  };

  componentWillReceiveProps({ InvoiceDetailsError }) {
    if (!_.isEqual(this.state.InvoiceDetailsError, InvoiceDetailsError)) {
      // this.setState({ InvoiceDetailsExpanded: InvoiceDetailsError });
      this.setState({ InvoiceDetailsError });
    }

    if (InvoiceDetailsError) {
      this.setState({ InvoiceDetailsExpanded: true });
    }
  }

  render() {
    const formikProps = this.props.formikProps;

    return (
      <div className="shop-days-panels">
        <Accordion.Item eventKey="5">
          <Accordion.Header
            style={{
              backgroundColor: this.state.InvoiceDetailsError ? "red" : "#555",
            }}
            onClick={() =>
              this.setState({
                InvoiceDetailsExpanded: !this.state.InvoiceDetailsExpanded,
              })
            }
          >
            <FormattedMessage
              id="branch.invoiceDetails"
              defaultMessage="Invoice Details"
            />
            <span
              className={classNames("glyphicon", "pull-right", {
                "glyphicon-plus": !this.state.InvoiceDetailsExpanded,
                "glyphicon-minus": this.state.InvoiceDetailsExpanded,
              })}
              aria-hidden="true"
            />
          </Accordion.Header>
          <Accordion.Body>
            <FieldRow
              id="centerNameEnglish"
              type="text"
              name="centerNameEnglish"
              labelId="branch.centerNameEnglish"
              placeholderId="branch.enterName"
              defaultLabel="Center Name English "
              defaultPlaceholder="Center Name English "
              formikProps={formikProps}
              showRequiredFlag={true}
            />
            <FieldRow
              id="centerNameArabic"
              type="text"
              name="centerNameArabic"
              labelId="branch.centerNameArabic"
              placeholderId="branch.enterName"
              defaultLabel="Center Name Arabic "
              defaultPlaceholder="Center Name Arabic "
              formikProps={formikProps}
              showRequiredFlag={true}
            />

            <FieldRow
              id="vatId"
              type="text"
              name="vatId"
              labelId="branch.vatId"
              placeholderId="branch.enterVatId"
              defaultLabel="Enter VAT ID"
              defaultPlaceholder="Enter VAT ID"
              formikProps={formikProps}
              showRequiredFlag={true}
            />
            <FieldRow
              id="contactNo"
              type="text"
              name="contactNo"
              labelId="branch.contactNo"
              placeholderId="branch.enterAddress"
              defaultLabel="Enter Contact number"
              defaultPlaceholder="Enter Contact number"
              formikProps={formikProps}
              showRequiredFlag={true}
            />
            <FieldRow
              id="crNo"
              type="text"
              name="crNo"
              labelId="branch.crNo"
              placeholderId="branch.crNo"
              defaultLabel="Enter CR number"
              defaultPlaceholder="Enter CR number"
              formikProps={formikProps}
              showRequiredFlag={true}
            />
          </Accordion.Body>
        </Accordion.Item>
      </div>
    );
  }
}

export default InvoiceDetails;
