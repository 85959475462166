import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import _ from "lodash";
import Auth from "../shared/Auth";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import Table from "../shared/Table";
import services from "../../services/index";
import classNames from "classnames";
import BranchesService from "../../services/branchesService";
import { SingleDatePicker } from "react-dates";
import ExportToExcel from "../shared/ExportToExcel";
import { generateInvoice, generateZatcaInvoice } from "../../util/orderUtil";
import { NotificationManager } from "react-notifications";
import errorMessage from "../../util/errorMessage";
import { cnd_order_status } from "../../util/utils";

const Orders = ({ navigate, match, location, intl }) => {
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState("all");
  const [count, setCount] = useState(0);
  const [disableFlag, setDisableFlag] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [loader, setLoader] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [branches, setBranches] = useState([]);
  const [branchFilter, setBranchFilter] = useState("all");
  const [orderTypeFilter, setOrderTypeFilter] = useState("all");
  const [appointmentFilter, setAppointmentFilter] = useState("all");
  const [eShopFilter, setEshopFilter] = useState("all");
  const [upsellFilter, setUpsellFilter] = useState("all");
  const [refundFilter, setRefundFilter] = useState("all");
  const [collectionAndDeliveryFilter, setCollectionAndDeliveryFilter] =
    useState("all");
  const [satisfiedFilter, setSatisfiedFilter] = useState("all");
  const [sourceFilter, setSourceFilter] = useState("all");
  const [paymentBrandFilter, setPaymentBrandFilter] = useState("all");
  const [focusedFrom, setFocusedFrom] = useState("");
  const [focusedTo, setFocusedTo] = useState("");
  const [branchIds, setBranchIds] = useState("");

  const searchInputRef = useRef(null);
  const searchButtonRef = useRef(null);
  const statusSelectRef = useRef(null);
  const orderTypeRef = useRef(null);
  const branchesRef = useRef(null);
  const refundStatusRef = useRef(null);
  const collectionAndDelivery = useRef(null);
  const satisfied = useRef(null);
  const source = useRef(null);
  // const paymentBrandRef = useRef(null);
  const appointmenSelectRef = useRef(null);
  const eShopSelectRef = useRef(null);
  const upsellSelectRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (location.pathname !== match.path) {
      if (!match.params.isFormOpen) {
        if (_.get(location, "state.isSubmitted") === true) {
          setPageIndex(-1);
          setData([]);
          setHasMore(true);
          setDisableFlag(true);
          setSearchFilter(searchInputRef.current?.value);
          setStatusFilter(statusSelectRef.current?.value);
          setBranchFilter(branchesRef.current?.value);
          setOrderTypeFilter(orderTypeRef.current?.value);
          setAppointmentFilter(appointmenSelectRef.current?.value);
          setEshopFilter(eShopSelectRef.current?.value);
          setUpsellFilter(upsellSelectRef.current?.value);
          setRefundFilter(refundStatusRef.current.value);
          setCollectionAndDeliveryFilter(collectionAndDelivery.current.value);
          setSatisfiedFilter(satisfied.current.value);
          setSourceFilter(source.current.value);
          // setPaymentBrandFilter(paymentBrandRef.current.value);
        } else {
        }
      }
    }
  }, [location.pathname, match.params.isFormOpen, location.state]);

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    setLoader(true);
    try {
      const branches = await BranchesService.getActiveBranches();
      const _branchIds = branches && branches?.map((branch) => branch._id);
      setBranchIds(JSON.stringify(_branchIds));
      setBranches(branches);
    } catch (e) {
      console.error(e);
    }
  };

  const loadMore = async () => {
    setLoader(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
      fromDate: from ? moment(from).startOf("day").toString() : null,
      toDate: to ? moment(to).endOf("day").toString() : null,
    };

    const pageData = await services.getOrders(
      searchFilter,
      statusFilter,
      branchFilter,
      orderTypeFilter,
      appointmentFilter,
      eShopFilter,
      branchIds,
      upsellFilter,
      refundFilter,
      collectionAndDeliveryFilter,
      satisfiedFilter,
      sourceFilter,
      paymentBrandFilter,
      pageIndex + 1,
      pageSize,
      {
        ...options,
        fromDate: from ? moment(from).startOf("day").toString() : null,
        toDate: to ? moment(to).endOf("day").toString() : null,
      }
    );
    let summary = { count };

    if (_pageIndex === 0) {
      summary = await services.getOrders(
        searchFilter,
        statusFilter,
        branchFilter,
        orderTypeFilter,
        appointmentFilter,
        eShopFilter,
        branchIds,
        upsellFilter,
        refundFilter,
        collectionAndDeliveryFilter,
        satisfiedFilter,
        sourceFilter,
        paymentBrandFilter,
        undefined,
        undefined,
        {},
        summary
      );
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setDisableFlag(false);
    setLoader(false);

    if (_pageIndex === 0) {
      setCount(summary.count);
    }
  };

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setDisableFlag(false);
    setSearchFilter(searchInputRef.current?.value);
    setStatusFilter(statusSelectRef.current?.value);
    setBranchFilter(branchesRef.current?.value);
    setOrderTypeFilter(orderTypeRef.current?.value);
    setAppointmentFilter(appointmenSelectRef.current?.value);
    setEshopFilter(eShopSelectRef.current?.value);
    setUpsellFilter(upsellSelectRef.current?.value);
    setRefundFilter(refundStatusRef.current.value);
    setCollectionAndDeliveryFilter(collectionAndDelivery.current.value);
    setSatisfiedFilter(satisfied.current.value);
    setSourceFilter(source.current.value);
    // setPaymentBrandFilter(paymentBrandRef.current.value);

    if (e) e.preventDefault();
  };

  useEffect(() => {
    const onLanguageChange = () => {
      setFrom(from ? moment(from).locale(appState.state.language) : undefined);
      setTo(to ? moment(to).locale(appState.state.language) : undefined);
    };
    const subscription = appState.emitter.addListener(
      "onLanguageChange",
      onLanguageChange
    );

    return () => {
      subscription.remove();
    };
  }, [from, to]);

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setOrderTypeFilter("all");
    setAppointmentFilter("all");
    setRefundFilter("all");
    setCollectionAndDeliveryFilter("all");
    setSatisfiedFilter("all");
    setSourceFilter("all");
    setUpsellFilter("all");
    setEshopFilter("all");
    setSearchFilter("");
    setStatusFilter("all");
    setDisableFlag(false);
    setTo(undefined);
    setFrom(undefined);
    setBranchFilter("all");
    searchInputRef.current.value = "";
    statusSelectRef.current.value = "all";
    branchesRef.current.value = "all";
    orderTypeRef.current.value = "all";
    appointmenSelectRef.current.value = "all";
    eShopSelectRef.current.value = "all";
    upsellSelectRef.current.value = "all";
    refundStatusRef.current.value = "all";
    collectionAndDelivery.current.value = "all";
    satisfied.current.value = "all";
    source.current.value = "all";
    // paymentBrandRef.current.value = "all";
    e.preventDefault();
  };
  const onExport = async (startDate, endDate) => {
    if (!startDate) return;
    if (!endDate) {
      endDate = startDate;
    }
    setLoader(true);
    try {
      // setExportExcelLoading(true);
      await services.downloadOrderReport(
        searchFilter,
        statusFilter,
        moment(startDate)
          .utcOffset(60 * 3)
          .startOf("day")
          .toString(),
        moment(endDate)
          .utcOffset(60 * 3)
          .endOf("day")
          .toString()
      );
    } catch (err) {}
    setLoader(false);
  };

  const handleInvoiceDownload = async ({ id, deposit, type, refund, e }) => {
    e.preventDefault();
    e.stopPropagation();

    setLoader(true);
    await generateInvoice({ id, deposit, type, refund });
    setLoader(false);
  };

  const handleZatcaInvoiceDownload = async (uuid, e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoader(true);
    const pdf = await generateZatcaInvoice(uuid);

    const pdfBlob = new Blob([pdf], { type: "application/pdf" });

    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");

    setLoader(false);
  };

  const handleResendInvoiceToZatca = async ({
    orderId,
    orderType,
    isDeposit = false,
    isRefund = false,
    e,
  }) => {
    e.preventDefault();
    e.stopPropagation();
    setLoader(true);

    try {
      const response = await services.resendInvoiceToZatca({
        orderId,
        orderType,
        isDeposit,
        isRefund,
      });
      if (response) {
        NotificationManager.success(
          intl.formatMessage({
            id: "global.operationSuccessful",
            defaultMessage: response,
          }),
          intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );
        if (formRef?.current) formRef?.current?.submit();
      }
    } catch (err) {
      console.log(err);
      NotificationManager.error(
        intl.formatMessage({
          id: errorMessage.Error[err?.response?.data?.key]?.key,
          defaultMessage:
            errorMessage.Error[err?.response?.data?.key]?.value ||
            "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setLoader(false);
    }
  };
  const handleResendInvoiceToOracle = async ({
    id,
    orderType,
    isDeposit = false,
    e,
  }) => {
    e.preventDefault();
    e.stopPropagation();
    setLoader(true);

    try {
      const response = await services.resendInvoiceToOracle({
        id,
        orderType,
        isDeposit,
      });
      if (response) {
        NotificationManager.success(
          intl.formatMessage({
            id: "global.operationSuccessful",
            defaultMessage: response,
          }),
          intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );
        if (formRef?.current) formRef?.current?.submit();
      }
    } catch (err) {
      console.log(err);
      NotificationManager.error(
        intl.formatMessage({
          id: errorMessage.Error[err?.response?.data?.key]?.key,
          defaultMessage:
            errorMessage.Error[err?.response?.data?.key]?.value ||
            "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setLoader(false);
    }
  };

  const routeToOrderDetails = (c) => navigate(`/order/${c._id}`);

  return (
    <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
      <Layout hidden={match.params.isFormOpen} loading={loader}>
        <div className="table-header clearfix">
          <HeaderTitle id="order.orders" defaultMessage="Orders" />
          <div>
            <CheckAccess activityId={ActivitiesEnum.orders_filter_and_search}>
              <div className="table-header clearfix">
                <form className="row" onSubmit={resetData} ref={formRef}>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <div className="has-feedback">
                      <input
                        type="text"
                        ref={searchInputRef}
                        className="form-control"
                        placeholder={intl.formatMessage({
                          id: "global.search",
                          defaultMessage: "Search",
                        })}
                      />
                      <i className="glyphicon glyphicon-search form-control-feedback" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <SingleDatePicker
                      id="fromDate"
                      placeholder={intl.formatMessage({
                        id: "global.from",
                        defaultMessage: "From",
                      })}
                      block={true}
                      small={true}
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      initialDate={from}
                      isRTL={appState.state.language === "ar" ? true : false}
                      date={from}
                      onDateChange={(date) => setFrom(date)}
                      focused={focusedFrom}
                      onFocusChange={({ focused }) => setFocusedFrom(focused)}
                    />
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <SingleDatePicker
                      id="toDate"
                      block={true}
                      placeholder={intl.formatMessage({
                        id: "global.to",
                        defaultMessage: "To",
                      })}
                      small={true}
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      initialDate={to}
                      isRTL={appState.state.language === "ar" ? true : false}
                      date={to}
                      onDateChange={(date) => {
                        setTo(date);
                      }}
                      focused={focusedTo}
                      onFocusChange={({ focused }) => setFocusedTo(focused)}
                    />
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={statusSelectRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.status",
                          defaultMessage: "Status",
                        })}
                      </option>
                      <option value="Yes">
                        {intl.formatMessage({
                          id: "order.active",
                          defaultMessage: "Active",
                        })}
                      </option>
                      <option value="No">
                        {intl.formatMessage({
                          id: "order.suspended",
                          defaultMessage: "Suspended",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={orderTypeRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.all",
                          defaultMessage: "Order Type",
                        })}
                      </option>
                      <option value="offer">
                        {intl.formatMessage({
                          id: "order.offer",
                          defaultMessage: "Offer",
                        })}
                      </option>
                      <option value="variant">
                        {intl.formatMessage({
                          id: "order.variant",
                          defaultMessage: "Variant",
                        })}
                      </option>
                      <option value="subscription">
                        {intl.formatMessage({
                          id: "order.subscription",
                          defaultMessage: "Subscription",
                        })}
                      </option>
                      {/* <option value="both">
                        {intl.formatMessage({
                          id: "order.both",
                          defaultMessage: "Both",
                        })}
                      </option> */}
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={appointmenSelectRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.appointment",
                          defaultMessage: "Appointment",
                        })}
                      </option>
                      <option value="true">
                        {intl.formatMessage({
                          id: "order.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value="false">
                        {intl.formatMessage({
                          id: "order.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={eShopSelectRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.eShop",
                          defaultMessage: "E-Shop",
                        })}
                      </option>
                      <option value="Yes">
                        {intl.formatMessage({
                          id: "order.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value="No">
                        {intl.formatMessage({
                          id: "order.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={upsellSelectRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.upsell",
                          defaultMessage: "Upsell",
                        })}
                      </option>
                      <option value="Yes">
                        {intl.formatMessage({
                          id: "order.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value="No">
                        {intl.formatMessage({
                          id: "order.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={refundStatusRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.all",
                          defaultMessage: "Refund Status",
                        })}
                      </option>
                      <option value={true}>
                        {intl.formatMessage({
                          id: "order.refunded",
                          defaultMessage: "Refunded",
                        })}
                      </option>
                      <option value={false}>
                        {intl.formatMessage({
                          id: "order.nonRefunded",
                          defaultMessage: "Non-Refunded",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={collectionAndDelivery}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.all",
                          defaultMessage: "Collection & Delivery",
                        })}
                      </option>
                      <option value={true}>
                        {intl.formatMessage({
                          id: "order.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value={false}>
                        {intl.formatMessage({
                          id: "order.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={satisfied}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.all",
                          defaultMessage: "Satisfied",
                        })}
                      </option>
                      <option value={true}>
                        {intl.formatMessage({
                          id: "order.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value={false}>
                        {intl.formatMessage({
                          id: "order.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={source}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.source",
                          defaultMessage: "Source",
                        })}
                      </option>
                      <option value="autohub">
                        {intl.formatMessage({
                          id: "order.autohub",
                          defaultMessage: "Autohub",
                        })}
                      </option>
                      <option value="carhub">
                        {intl.formatMessage({
                          id: "order.carhub",
                          defaultMessage: "Carhub",
                        })}
                      </option>
                    </select>
                  </div>
                  {/* <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={paymentBrandRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.appointmentOrEShop",
                          defaultMessage: "Appointment Or E-Shop",
                        })}
                      </option>
                      <option value="e-shop">
                        {intl.formatMessage({
                          id: "order.eShop",
                          defaultMessage: "E-Shop",
                        })}
                      </option>
                    </select>
                  </div> */}
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={branchesRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.branches",
                          defaultMessage: "Branches",
                        })}
                      </option>
                      {branches?.map((branch) => (
                        <option key={branch?._id} value={branch?._id}>
                          {appState.state.language === "en"
                            ? branch?.name?.value_en
                            : branch?.name?.value_ar}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        className="btn btn-primary"
                        onClick={resetData}
                        disabled={disableFlag}
                        ref={searchButtonRef}
                      >
                        <i className="material-icons">search</i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.filter"
                          defaultMessage="Filter"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        className="btn btn-primary"
                        onClick={resetPage}
                        disabled={disableFlag}
                      >
                        <i className="material-icons">
                          settings_backup_restore
                        </i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.resetFilter"
                          defaultMessage="Reset Filter"
                        />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </CheckAccess>
            <div className="col-xs-3 pull-right">
              <div className="panel-group-buttons pull-right">
                <CheckAccess activityId={ActivitiesEnum.orders_export_to_excel}>
                  <ExportToExcel onExport={onExport} />
                </CheckAccess>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <CheckAccess activityId={ActivitiesEnum.variant__add_variant}>
                    <Link className="btn btn-special " to="/variant">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="variants.new_variant"
                        defaultMessage="New Variant"
                      />
                    </Link>
                  </CheckAccess>
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />

                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={count || 0}
                  />{" "}
                </strong>
              </div>
            </div>
          </div>
          <CheckAccess activityId={ActivitiesEnum.orders_table}>
            <div className="table-responsive">
              {branchIds?.length ? (
                <Table
                  loadMore={loadMore}
                  hasMore={hasMore}
                  caption={
                    (!data || data?.length === 0) && (
                      <caption
                        style={{
                          captionSide: "bottom",
                          textAlign: "center",
                          margin: "20px",
                          color: "darkgray",
                        }}
                      >
                        <FormattedMessage
                          id="global.noData"
                          defaultMessage="No data available"
                        />
                      </caption>
                    )
                  }
                  headerRow={
                    <tr onClick={() => {}}>
                      <th>
                        <FormattedMessage
                          id="order.placementTime"
                          defaultMessage="Order Placement time"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.orderId"
                          defaultMessage="Order Id"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.type"
                          defaultMessage="Order Type"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.appointmentRef"
                          defaultMessage="Appointment Id (If Any)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.packageId"
                          defaultMessage="Subscription Id (If Any)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.branch"
                          defaultMessage="Branch Name"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.customerNo"
                          defaultMessage="Customer Number"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.plateId"
                          defaultMessage="Car Plate Number"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.items"
                          defaultMessage="No Of Items"
                        />
                      </th>
                      {/* <th>
                    <FormattedMessage
                      id="order.category"
                      defaultMessage="Category Name"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="order.product"
                      defaultMessage="Product Name"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="order.variant"
                      defaultMessage="Variant Name"
                    />
                  </th> */}
                      <th>
                        <FormattedMessage
                          id="order.amount"
                          defaultMessage="Total Amount (SAR)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.paymentStatus"
                          defaultMessage="Paid"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.cadStatus"
                          defaultMessage="CND Status"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.refunded"
                          defaultMessage="Refunded"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.refundAt"
                          defaultMessage="Refund At"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.downloadDepositReceipt"
                          defaultMessage="Offer Deposit Receipt"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.downloadDepositReceipt"
                          defaultMessage="Product Deposit Receipt"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.downloadDepositReceipt"
                          defaultMessage="Subscription Deposit Receipt"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.finalInvoice"
                          defaultMessage="Final Invoice (Offer)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.finalInvoice"
                          defaultMessage="Final Invoice (Product)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.finalInvoice"
                          defaultMessage="Final Invoice (Subscription)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.zatcaInvoice"
                          defaultMessage="Zatca Deposit Invoice (Product)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.zatcaInvoice"
                          defaultMessage="Zatca Deposit Invoice (Offer)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.zatcaInvoice"
                          defaultMessage="Zatca Deposit Invoice (Subscription)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.zatcaInvoice"
                          defaultMessage="Zatca Final Invoice (Product)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.zatcaInvoice"
                          defaultMessage="Zatca Final Invoice (Offer)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.zatcaInvoice"
                          defaultMessage="Zatca Final Invoice (Subscription)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.offerRefundInvoice"
                          defaultMessage="Offer Refund Invoice"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.variantRefundInvoice"
                          defaultMessage="Variant Refund Invoice"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.zatcaROfferRefundInvoice"
                          defaultMessage="Zatca Offer Refund Invoice"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.zatcaVariantRefundInvoice"
                          defaultMessage="Zatca Variant Refund Invoice"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.oracleDepositInvoiceVariant"
                          defaultMessage="Sent Oracle Depsoit Invoice (Variant)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.oracleFinalInvoiceVariant"
                          defaultMessage="Sent Oracle Final Invoice (Variant)"
                        />
                      </th>

                      <th>
                        <FormattedMessage
                          id="order.oracleDepositInvoiceOffer"
                          defaultMessage="Sent Oracle Depsoit Invoice (Offer)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.oracleFinalInvoiceOffer"
                          defaultMessage="Sent Oracle Final Invoice (Offer)"
                        />
                      </th>

                      <th style={{ width: "20px" }} />
                    </tr>
                  }
                >
                  {data?.map((c) => {
                    const isCNDOrder = c.isCNDOrder?.length;
                    const cndStatus = c.cndStatus === "deliveredToCustomer";

                    return (
                      <tr
                        key={c._id}
                        className={`cases-trow link-row ${
                          c.isPaid ? "paid-order" : "unpaid-order"
                        }-record`}
                        onClick={() => routeToOrderDetails(c)}
                      >
                        <td>{moment(c?.createdAt).format("DD-MM-YYYY")}</td>
                        <td>{c?.orderIdHash}</td>
                        <td>{c?.orderType}</td>
                        <td>{c?.appointmentHash || "N/A"}</td>
                        <td>
                          {c.subscribedSubscription?.[0]?.hashId || "N/A"}
                        </td>
                        <td>
                          {appState.state.language === "en"
                            ? c.branch?.name?.value_en
                            : c.branch?.name?.value_ar}
                        </td>
                        <td>{c?.phoneNumber}</td>
                        <td>{c?.carNumberPlate || "N/A"}</td>
                        <td>
                          {c?.variantItems?.length + c?.offerItems?.length || 0}
                        </td>
                        <td>
                          {c?.orderDetermination?.priceAfterPromoDiscount || 0}
                        </td>
                        <td
                          className={classNames({
                            "col-status-green": c.isPaid,
                            "col-status-red": !c.isPaid,
                          })}
                        >
                          <a>
                            {c.isPaid && (
                              <FormattedMessage
                                id="global.active"
                                defaultMessage="Yes"
                              />
                            )}
                            {!c.isPaid && (
                              <FormattedMessage
                                id="global.suspended"
                                defaultMessage="No"
                              />
                            )}
                          </a>
                        </td>
                        <td
                          className={classNames({
                            "col-status-green": isCNDOrder && cndStatus,
                            "col-status-red": isCNDOrder && !cndStatus,
                          })}
                        >
                          {isCNDOrder ? (
                            <a>
                              <FormattedMessage
                                id={"cndStatus." + c.cndStatus}
                                defaultMessage={cnd_order_status[c.cndStatus]}
                              />
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td
                          className={classNames({
                            "col-status-green": c.isRefunded,
                            "col-status-red": !c.isRefunded,
                          })}
                        >
                          <a>
                            {c.isRefunded && (
                              <FormattedMessage
                                id="global.active"
                                defaultMessage="Yes"
                              />
                            )}
                            {!c.isRefunded && (
                              <FormattedMessage
                                id="global.suspended"
                                defaultMessage="No"
                              />
                            )}
                          </a>
                        </td>
                        <td>
                          {c?.isRefunded
                            ? moment(c?.refundAt).format("YYYY-MM-DD, HH:mm:ss")
                            : "-"}
                        </td>
                        <td className="download-icon">
                          {c?.offerItems?.length ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleInvoiceDownload({
                                  id: c?._id,
                                  deposit: true,
                                  type: "offer",
                                  e,
                                })
                              }
                            ></i>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.variantItems?.length ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleInvoiceDownload({
                                  id: c?._id,
                                  deposit: true,
                                  type: "variant",
                                  e,
                                })
                              }
                            ></i>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.subscriptionItems?.length ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleInvoiceDownload({
                                  id: c?._id,
                                  deposit: true,
                                  type: "subscription",
                                  e,
                                })
                              }
                            ></i>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.offerCompletedBy ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleInvoiceDownload({
                                  id: c?._id,
                                  deposit: false,
                                  type: "offer",
                                  e,
                                })
                              }
                            ></i>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.variantDeliveredBy ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleInvoiceDownload({
                                  id: c?._id,
                                  deposit: false,
                                  type: "variant",
                                  e,
                                })
                              }
                            ></i>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.subscriptionCompletedBy ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleInvoiceDownload({
                                  id: c?._id,
                                  deposit: false,
                                  type: "subscription",
                                  e,
                                })
                              }
                            ></i>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.zatcaInvoiceDetails?.zatcaDepositVariantUuid ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleZatcaInvoiceDownload(
                                  c?.zatcaInvoiceDetails
                                    ?.zatcaDepositVariantUuid,
                                  e
                                )
                              }
                            ></i>
                          ) : (c?.orderType === "eshop" ||
                              c?.orderType === "addon") &&
                            c?.variantItems?.length > 0 &&
                            c?.zatcaInvoiceDetails
                              ?.variantDepositRequestUniqueIdentifier &&
                            !c?.zatcaInvoiceDetails?.zatcaDepositVariantUuid ? (
                            <input
                              type="button"
                              className="btn btn-primary resend-invoice"
                              value={intl.formatMessage({
                                id: "global.resend",
                                defaultMessage: "Resend Invoice",
                              })}
                              onClick={(e) =>
                                handleResendInvoiceToZatca({
                                  orderId: c?._id,
                                  orderType: "variant",
                                  isDeposit: true,
                                  e,
                                })
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="text-center download-icon">
                          {c?.zatcaInvoiceDetails?.zatcaDepositOfferUuid ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleZatcaInvoiceDownload(
                                  c?.zatcaInvoiceDetails?.zatcaDepositOfferUuid,
                                  e
                                )
                              }
                            ></i>
                          ) : (c?.orderType === "offer" ||
                              c?.orderType === "addon") &&
                            c?.offerItems?.length > 0 &&
                            c?.zatcaInvoiceDetails
                              ?.offerDepositRequestUniqueIdentifier &&
                            !c?.zatcaInvoiceDetails?.zatcaDepositOfferUuid ? (
                            <input
                              type="button"
                              className="btn btn-primary resend-invoice"
                              value={intl.formatMessage({
                                id: "global.resend",
                                defaultMessage: "Resend Invoice",
                              })}
                              onClick={(e) =>
                                handleResendInvoiceToZatca({
                                  orderId: c?._id,
                                  orderType: "offer",
                                  isDeposit: true,
                                  e,
                                })
                              }
                            />
                          ) : c?.upsellServiceStatus === "completed" &&
                            c?.zatcaInvoiceDetails
                              ?.offerDepositRequestUniqueIdentifier &&
                            !c?.zatcaInvoiceDetails?.zatcaDepositOfferUuid ? (
                            <input
                              type="button"
                              className="btn btn-primary resend-invoice"
                              value={intl.formatMessage({
                                id: "global.resend",
                                defaultMessage: "Resend Invoice",
                              })}
                              onClick={(e) =>
                                handleResendInvoiceToZatca({
                                  orderId: c?._id,
                                  orderType: "upsellService",
                                  isDeposit: true,
                                  e,
                                })
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="text-center download-icon">
                          {c?.zatcaInvoiceDetails
                            ?.zatcaDepositSubscriptionUuid ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleZatcaInvoiceDownload(
                                  c?.zatcaInvoiceDetails
                                    ?.zatcaDepositSubscriptionUuid,
                                  e
                                )
                              }
                            ></i>
                          ) : (
                            c?.orderType === "subscription" &&
                            c?.subscriptionItems?.length > 0 &&
                            c?.zatcaInvoiceDetails
                              ?.subscriptionDepositRequestUniqueIdentifier &&
                            !c?.zatcaInvoiceDetails
                              ?.zatcaDepositSubscriptionUuid && (
                              <input
                                type="button"
                                className="btn btn-primary resend-invoice"
                                value={intl.formatMessage({
                                  id: "global.resend",
                                  defaultMessage: "Resend Invoice",
                                })}
                                onClick={(e) =>
                                  handleResendInvoiceToZatca({
                                    orderId: c?._id,
                                    orderType: "subscription",
                                    isDeposit: true,
                                    e,
                                  })
                                }
                              />
                            )
                          )}
                        </td>
                        {/* <td className="text-center download-icon"> - </td> */}
                        <td className="download-icon">
                          {c?.zatcaInvoiceDetails?.zatcaFinalVariantUuid ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleZatcaInvoiceDownload(
                                  c?.zatcaInvoiceDetails?.zatcaFinalVariantUuid,
                                  e
                                )
                              }
                            ></i>
                          ) : (c?.orderType === "eshop" ||
                              c?.orderType === "addon") &&
                            c?.variantItems?.length > 0 &&
                            c?.variantCompletedStatus === "completed" &&
                            c?.zatcaInvoiceDetails
                              ?.variantFinalRequestUniqueIdentifier &&
                            !c?.zatcaInvoiceDetails?.zatcaFinalVariantUuid ? (
                            <input
                              type="button"
                              className="btn btn-primary resend-invoice"
                              value={intl.formatMessage({
                                id: "global.resend",
                                defaultMessage: "Resend Invoice",
                              })}
                              onClick={(e) =>
                                handleResendInvoiceToZatca({
                                  orderId: c?._id,
                                  orderType: "variant",
                                  isDeposit: false,
                                  e,
                                })
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="text-center download-icon">
                          {c?.zatcaInvoiceDetails?.zatcaFinalOfferUuid ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleZatcaInvoiceDownload(
                                  c?.zatcaInvoiceDetails?.zatcaFinalOfferUuid,
                                  e
                                )
                              }
                            ></i>
                          ) : (c?.orderType === "offer" ||
                              c?.orderType === "addon") &&
                            c?.offerItems?.length > 0 &&
                            c?.offerCompletedStatus === "completed" &&
                            c?.zatcaInvoiceDetails
                              ?.offerFinalRequestUniqueIdentifier &&
                            !c?.zatcaInvoiceDetails?.zatcaFinalOfferUuid ? (
                            <input
                              type="button"
                              className="btn btn-primary resend-invoice"
                              value={intl.formatMessage({
                                id: "global.resend",
                                defaultMessage: "Resend Invoice",
                              })}
                              onClick={(e) =>
                                handleResendInvoiceToZatca({
                                  orderId: c?._id,
                                  orderType: "offer",
                                  isDeposit: false,
                                  e,
                                })
                              }
                            />
                          ) : c?.upsellServiceStatus === "completed" &&
                            c?.zatcaInvoiceDetails
                              ?.offerFinalRequestUniqueIdentifier &&
                            !c?.zatcaInvoiceDetails?.zatcaFinalOfferUuid ? (
                            <input
                              type="button"
                              className="btn btn-primary resend-invoice"
                              value={intl.formatMessage({
                                id: "global.resend",
                                defaultMessage: "Resend Invoice",
                              })}
                              onClick={(e) =>
                                handleResendInvoiceToZatca({
                                  orderId: c?._id,
                                  orderType: "upsellService",
                                  isDeposit: false,
                                  e,
                                })
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="text-center download-icon">
                          {c?.zatcaInvoiceDetails
                            ?.zatcaFinalSubscriptionUuid ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleZatcaInvoiceDownload(
                                  c?.zatcaInvoiceDetails
                                    ?.zatcaFinalSubscriptionUuid,
                                  e
                                )
                              }
                            ></i>
                          ) : (
                            c?.orderType === "subscription" &&
                            c?.subscriptionItems?.length > 0 &&
                            c?.subscriptionCompletedStatus === "completed" &&
                            c?.zatcaInvoiceDetails
                              ?.subscriptionFinalRequestUniqueIdentifier &&
                            !c?.zatcaInvoiceDetails
                              ?.zatcaFinalSubscriptionUuid && (
                              <input
                                type="button"
                                className="btn btn-primary resend-invoice"
                                value={intl.formatMessage({
                                  id: "global.resend",
                                  defaultMessage: "Resend Invoice",
                                })}
                                onClick={(e) =>
                                  handleResendInvoiceToZatca({
                                    orderId: c?._id,
                                    orderType: "subscription",
                                    isDeposit: false,
                                    e,
                                  })
                                }
                              />
                            )
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.offerItems?.length > 0 && c?.isRefunded ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleInvoiceDownload({
                                  id: c?._id,
                                  deposit: true,
                                  type: "offer",
                                  refund: true,
                                  e,
                                })
                              }
                            ></i>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.variantItems?.length && c?.isRefunded ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleInvoiceDownload({
                                  id: c?._id,
                                  deposit: true,
                                  type: "variant",
                                  refund: true,
                                  e,
                                })
                              }
                            ></i>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.zatcaInvoiceDetails?.zatcaOfferRefundUuid ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleZatcaInvoiceDownload(
                                  c?.zatcaInvoiceDetails?.zatcaOfferRefundUuid,
                                  e
                                )
                              }
                            ></i>
                          ) : (c?.orderType === "eshop" ||
                              c?.orderType === "addon") &&
                            c?.offerItems?.length > 0 &&
                            c?.zatcaInvoiceDetails
                              ?.offerRefundRequestUniqueIdentifier &&
                            !c?.zatcaInvoiceDetails?.zatcaOfferRefundUuid ? (
                            <input
                              type="button"
                              className="btn btn-primary resend-invoice"
                              value={intl.formatMessage({
                                id: "global.resend",
                                defaultMessage: "Resend Invoice",
                              })}
                              onClick={(e) =>
                                handleResendInvoiceToZatca({
                                  orderId: c?._id,
                                  orderType: "offer",
                                  isRefund: true,
                                  e,
                                })
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.zatcaInvoiceDetails?.zatcaVariantRefundUuid ? (
                            <i
                              className="fa fa-download"
                              onClick={(e) =>
                                handleZatcaInvoiceDownload(
                                  c?.zatcaInvoiceDetails
                                    ?.zatcaVariantRefundUuid,
                                  e
                                )
                              }
                            ></i>
                          ) : (c?.orderType === "eshop" ||
                              c?.orderType === "addon") &&
                            c?.variantItems?.length > 0 &&
                            c?.zatcaInvoiceDetails
                              ?.variantRefundRequestUniqueIdentifier &&
                            !c?.zatcaInvoiceDetails?.zatcaVariantRefundUuid ? (
                            <input
                              type="button"
                              className="btn btn-primary resend-invoice"
                              value={intl.formatMessage({
                                id: "global.resend",
                                defaultMessage: "Resend Invoice",
                              })}
                              onClick={(e) =>
                                handleResendInvoiceToZatca({
                                  orderId: c?._id,
                                  orderType: "variant",
                                  isRefund: true,
                                  e,
                                })
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          className={classNames({
                            "col-status-green":
                              c.isVariantDepositOracleInvoiceProceed,
                            "col-status-red":
                              !c.isVariantDepositOracleInvoiceProceed,
                          })}
                        >
                          {c?.variantItems?.length > 0 ? (
                            !c?.isVariantDepositOracleInvoiceProceed ? (
                              c?.isVariantDepositOracleInvoiceProceed ===
                              false ? (
                                <input
                                  type="button"
                                  className="btn btn-primary resend-invoice"
                                  value={intl.formatMessage({
                                    id: "global.resend",
                                    defaultMessage: "Resend Invoice",
                                  })}
                                  onClick={(e) =>
                                    handleResendInvoiceToOracle({
                                      id: c?._id,
                                      orderType: "variant",
                                      isDeposit: true,
                                      e,
                                    })
                                  }
                                />
                              ) : (
                                "-"
                              )
                            ) : (
                              <a>
                                <FormattedMessage
                                  id="global.sent"
                                  defaultMessage="Sent"
                                />
                              </a>
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          className={classNames({
                            "col-status-green":
                              c.isVariantFinalOracelInvoiceProceed,
                            "col-status-red":
                              !c.isVariantFinalOracelInvoiceProceed,
                          })}
                        >
                          {c?.variantItems?.length > 0 &&
                          c?.variantCompletedStatus !== "pending" > 0 ? (
                            !c?.isVariantFinalOracelInvoiceProceed ? (
                              c?.isVariantFinalOracelInvoiceProceed ===
                              false ? (
                                <input
                                  type="button"
                                  className="btn btn-primary resend-invoice"
                                  value={intl.formatMessage({
                                    id: "global.resend",
                                    defaultMessage: "Resend Invoice",
                                  })}
                                  onClick={(e) =>
                                    handleResendInvoiceToOracle({
                                      id: c?._id,
                                      orderType: "variant",
                                      isDeposit: false,
                                      e,
                                    })
                                  }
                                />
                              ) : (
                                "-"
                              )
                            ) : (
                              <a>
                                <FormattedMessage
                                  id="global.sent"
                                  defaultMessage="Sent"
                                />
                              </a>
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          className={classNames({
                            "col-status-green":
                              c.isOfferDepositOracleInvoiceProceed,
                            "col-status-red":
                              !c.isOfferDepositOracleInvoiceProceed,
                          })}
                        >
                          {c?.offerItems?.length > 0 ? (
                            !c?.isOfferDepositOracleInvoiceProceed ? (
                              c?.isOfferDepositOracleInvoiceProceed ===
                              false ? (
                                <input
                                  type="button"
                                  className="btn btn-primary resend-invoice"
                                  value={intl.formatMessage({
                                    id: "global.resend",
                                    defaultMessage: "Resend Invoice",
                                  })}
                                  onClick={(e) =>
                                    handleResendInvoiceToOracle({
                                      id: c?._id,
                                      orderType: "offer",
                                      isDeposit: true,
                                      e,
                                    })
                                  }
                                />
                              ) : (
                                "-"
                              )
                            ) : (
                              <a>
                                <FormattedMessage
                                  id="global.sent"
                                  defaultMessage="Sent"
                                />
                              </a>
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          className={classNames({
                            "col-status-green":
                              c.isOfferFinalOracelInvoiceProceed,
                            "col-status-red":
                              !c.isOfferFinalOracelInvoiceProceed,
                          })}
                        >
                          {c?.offerItems?.length > 0 &&
                          !["pending", "notStarted"]?.includes(
                            c?.offerStatus
                          ) ? (
                            !c?.isOfferFinalOracelInvoiceProceed ? (
                              c?.isOfferFinalOracelInvoiceProceed === false ? (
                                <input
                                  type="button"
                                  className="btn btn-primary resend-invoice"
                                  value={intl.formatMessage({
                                    id: "global.resend",
                                    defaultMessage: "Resend Invoice",
                                  })}
                                  onClick={(e) =>
                                    handleResendInvoiceToOracle({
                                      id: c?._id,
                                      orderType: "offer",
                                      isDeposit: false,
                                      e,
                                    })
                                  }
                                />
                              ) : (
                                "-"
                              )
                            ) : (
                              <a>
                                <FormattedMessage
                                  id="global.sent"
                                  defaultMessage="Sent"
                                />
                              </a>
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              ) : (
                <></>
              )}
            </div>
          </CheckAccess>
        </div>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(Orders));
