import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import Layout from "../shared/Layout";
import AppointmentService from "../../services/appointmentService";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import { Button } from "react-bootstrap";
import moment from "moment";
import { NotificationManager } from "react-notifications";

export class CollectionAppointmentForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
    this.state = {
      suggestion: [],
      data: {},
      loading: true,
    };
  }

  componentDidMount() {
    this.subscription = appState.emitter.addListener("onLanguageChange", () => {
      this.formik.validateForm();
    });

    this.getCollectionAppointmentData();
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async getCollectionAppointmentData() {
    try {
      const { id } = this.props.match.params;
      const options = { progressBar: true, appointment: true };

      if (id) {
        const data = await AppointmentService.getCollectionAppointmentById(
          id,
          options
        );
        this.setState({ data });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  async markAsCollected() {
    this.setState({ loading: true });

    try {
      await AppointmentService.updateCollectionAppoinmentStatus(
        this.props.match.params.id
      );

      this.getCollectionAppointmentData();

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      console.error(e);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      this.setState({ loading: false });
    }
  }

  _renderBody(formikProps) {
    const { isSubmitting, values } = formikProps;
    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <HeaderTitle
          id="collectionAppointment.collectionAppointments"
          defaultMessage="Collection Appointments"
        />

        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              <FormattedMessage
                id="collectionAppointment.collectionAppointmentRequest"
                defaultMessage="Collection Appointment Request"
              />
            </header>

            <Form>
              <div className="dashboard-row">
              <FieldRow
                  type="text"
                  name="hashId"
                  labelId="collectionAppointment.hashId"
                  defaultLabel="Reference Id"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="title"
                  labelId="collectionAppointment.title"
                  defaultLabel="Title"
                  placeholderId="collectionAppointment.enterTitle"
                  defaultPlaceholder="Enter title"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="firstName"
                  labelId="collectionAppointment.firstName"
                  defaultLabel="First Name"
                  placeholderId="collectionAppointment.enterFirstName"
                  defaultPlaceholder="Enter first name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="lastName"
                  labelId="collectionAppointment.lastName"
                  defaultLabel="Last Name"
                  placeholderId="collectionAppointment.enterLastName"
                  defaultPlaceholder="Enter last name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />

                <FieldRow
                  type="text"
                  name="phoneNumber"
                  labelId="collectionAppointment.phoneNumber"
                  defaultLabel="Phone Number"
                  placeholderId="collectionAppointment.enterPhoneNumber"
                  defaultPlaceholder="Enter phone number"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="appointmentHash"
                  labelId="collectionAppointment.appointmentHash"
                  defaultLabel="Appointment Hash"
                  placeholderId="collectionAppointment.appointmentHash"
                  defaultPlaceholder="Appointment Hash"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="serviceName"
                  labelId="collectionAppointment.serviceName"
                  defaultLabel="Service Name"
                  placeholderId="collectionAppointment.serviceName"
                  defaultPlaceholder="Service Name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="branchName"
                  labelId="collectionAppointment.branchName"
                  defaultLabel="Branch Name"
                  placeholderId="collectionAppointment.branchName"
                  defaultPlaceholder="Enter phone number"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="deliveryTime"
                  labelId="collectionAppointment.deliveryTime"
                  defaultLabel="Delivery Time"
                  placeholderId="collectionAppointment.deliveryTime"
                  defaultPlaceholder="Enter model of your product"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="make"
                  labelId="collectionAppointment.make"
                  defaultLabel="Make"
                  placeholderId="collectionAppointment.enterMake"
                  defaultPlaceholder="Enter make of your product"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="model"
                  labelId="collectionAppointment.model"
                  defaultLabel="Model"
                  placeholderId="collectionAppointment.enterModel"
                  defaultPlaceholder="Enter model of your product"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                {values?.otherBrand && values?.otherModel ? (
                  <>
                    <FieldRow
                      type="text"
                      name="otherBrand"
                      labelId="collectionAppointment.otherBrand"
                      defaultLabel="Other Brand"
                      placeholderId="collectionAppointment.otherBrand"
                      defaultPlaceholder="Enter other brand"
                      showRequiredFlag={true}
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="otherModel"
                      labelId="collectionAppointment.otherModel"
                      defaultLabel="Other Model"
                      placeholderId="collectionAppointment.otherModel"
                      defaultPlaceholder="Enter other model"
                      showRequiredFlag={true}
                      formikProps={formikProps}
                      disabled={true}
                    />
                  </>
                ) : null}
                {values?.otherModel && !values?.otherBrand ? (
                  <>
                    <FieldRow
                      type="text"
                      name="otherModel"
                      labelId="collectionAppointment.otherModel"
                      defaultLabel="Other Model"
                      placeholderId="collectionAppointment.otherModel"
                      defaultPlaceholder="Enter other model"
                      showRequiredFlag={true}
                      formikProps={formikProps}
                      disabled={true}
                    />
                  </>
                ) : null}
                <FieldRow
                  type="text"
                  name="carNickName"
                  defaultLabel="Car Nick Name"
                  labelId="appointmentCreate.carNickName"
                  placeholderId="appointmentCreate.carNickName"
                  defaultPlaceholder="Enter car nick name"
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="year"
                  labelId="collectionAppointment.year"
                  defaultLabel="Year"
                  placeholderId="collectionAppointment.year"
                  defaultPlaceholder="Enter year of your product"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={true}
                />
                <div className="two-fields">
                  <FieldRow
                    type="number"
                    name="plateNumber"
                    defaultLabel="Car Plate Number"
                    labelId="collectionAppointment.licencePlate"
                    defaultPlaceholder="5361"
                    placeholderId="collectionAppointment.licencePlate"
                    pattern="[0-9]*"
                    showRequiredFlag={true}
                    colSize={4}
                    formikProps={formikProps}
                    disabled={true}
                  />
                  <FieldRow
                    type="text"
                    name="plateChar"
                    defaultPlaceholder="S D F / ق د و"
                    placeholderId="collectionAppointment.licencePlate"
                    formikProps={formikProps}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="dashboard-row -nobottom d-flex justify-content-between">
                <div>
                  {!this.state.data.isCollected ? (
                    <input
                      type="button"
                      className="btn btn-primary"
                      value={this.props.intl.formatMessage({
                        id: "collectionAppointment.markCollectedBtn",
                        defaultMessage: "Car Collected",
                      })}
                      onClick={this.markAsCollected.bind(this)}
                      disabled={isSubmitting}
                    />
                  ) : (
                    <h4 className="text-success" style={{ fontWeight: "bold" }}>
                      <FormattedMessage
                        id="collectionAppointment.statusCollected"
                        defaultMessage="Collected"
                      />
                    </h4>
                  )}
                </div>
                <div>
                  <Button
                    variant="link"
                    onClick={() =>
                      this.props.navigate("/collection-appointments")
                    }
                    disabled={isSubmitting}
                  >
                    {this.props.intl.formatMessage({
                      id: "global.back",
                      defaultMessage: "Back",
                    })}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Layout>
    );
  }

  render() {
    const { data } = this.state;
    const { language } = appState?.state;
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            hashId: data?.collectionHashId,
            title: data?.title || "",
            firstName: data?.firstName || "",
            lastName: data?.lastName || "",
            phoneNumber: data?.phoneNumber || "",
            appointmentHash: data?.appointmentHash || "-",
            make: data?.carDetails?.brand?.["value_" + language] || "",
            model: data?.carDetails?.product?.["value_" + language] || "",
            year: data?.carDetails?.modelYear || "",
            otherBrand: data?.otherBrand || "",
            otherModel: data?.otherModel || "",
            carNickName: data?.carNickName || "",
            plateNumber: data?.carNumberPlate?.substring(0, 4) || "",
            plateChar: data?.carNumberPlate?.substring(5) || "",
            serviceName:
              data?.services?.[0]?.name?.["value_" + language] || "-",
            branchName: data?.branch?.name?.["value_" + language],
            deliveryTime:
              moment(data?.carCollectionAt?.date).format("MMMM D, YYYY") +
              " " +
              data?.carCollectionAt?.slot,
          }}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(
  withRouter(injectIntl(CollectionAppointmentForm))
);
