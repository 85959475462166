import { Container } from "unstated";
import $ from "jquery";
import { Yup } from "../util";
import yupArLocale from "../localization/yup-locale-ar";
import yupEnLocale from "../localization/yup-locale-en";
import { EventEmitter } from "fbemitter";
import moment from "moment";
import _ from "lodash";
import MomentLocalizer from "react-widgets-moment";
import axios from "axios";
import { sessionErrors } from "../util/utils";
export class AppStateContainer extends Container {
  localizer = (momentConfig) => new MomentLocalizer(momentConfig);
  emitter = new EventEmitter();
  state = {
    tmpToken: this.loadObj("tmpToken"),
    token: this.loadObj("token"),
    loaded: true,
    language: this.normalizeLanguageCode(this.loadString("language") || "ar"),
    sidebarActive: false,
    editingTranslationId: null,
    editingTranslationDefault: null,
    messages: {},
    currentLongitude: undefined,
    currentLatitude: undefined,
  };

  normalizeLanguageCode(code) {
    if (code.indexOf("-") >= 0) {
      return code.split("-")[0];
    }
    return code;
  }

  persistObj(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  loadObj(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  persistString(key, value) {
    localStorage.setItem(key, value);
  }

  loadString(key) {
    return localStorage.getItem(key);
  }

  constructor() {
    super();

    // Load any async state here
    // let locale = this.state.language === "ar" ? yupArLocale : yupEnLocale;
    // Yup.setLocale(locale);
    // moment.locale(this.state.language);
    // this.localizer(moment);
    this.setState({ loaded: true }, () => {
      let locale = this.state.language === "ar" ? yupArLocale : yupEnLocale;
      Yup.setLocale(locale);
      moment.locale(this.state.language);
      this.localizer(moment);
    });
  }

  async setToken(token) {
    this.persistObj("token", token);
    if (!!token && token.role == "sadmin" && !this.state.superToken) {
      this.persistObj("superToken", token);
      await this.setState({ superToken: token, token });
      return;
    }
    await this.setState({ token });
  }

  async setLoginAsToken(token) {
    const tmpToken = this.state.token;
    if (this.state.token.role == "sadmin") {
      this.persistObj("superToken", this.state.token);
      this.setState({ superToken: this.state.token });
    }

    this.state.token.route = undefined;
    this.persistObj("tmpToken", this.state.token);
    this.persistObj("token", token);
    await this.setState({ token, tmpToken });
  }

  setHeaderTitle(title, titleId) {
    this.persistString("title", title);
    this.persistString("titleId", titleId);
    this.setState({ title: title, titleId });
  }

  async setLanguage(language) {
    this.persistString("language", language);
    let locale = language === "ar" ? yupArLocale : yupEnLocale;
    Yup.setLocale(locale);
    moment.locale(language);
    this.localizer(moment);
    this.setState({ language }, () => {
      this.emitter.emit("onLanguageChange");
    });
  }

  setSidebarActive(isActive) {
    this.setState({ sidebarActive: isActive }, () => {
      if (this.state.sidebarActive) {
        $("body").addClass("overflow backdrop");
        // $(".navbar.navbar-fixed-top").css("width", "calc(100% - 240px)");
        $(".navbar-header").css("padding-left", "0px");
      } else {
        $("body").removeClass("overflow backdrop");
        // $(".navbar.navbar-fixed-top").css("width", "100%");
        // $(".navbar-header").css("padding-left", "220px");
      }
    });
  }

  changeLanguage() {
    const lang = this.state.language === "ar" ? "en" : "ar";
    this.setLanguage(lang);
    this.changeLanguageLayout(lang);
  }

  changeLanguageLayout(lang) {
    if (lang === "ar") $("body").addClass("rtl");
    else $("body").removeClass("rtl");
  }

  changeLanguageTo(language) {
    this.setLanguage(language);
  }

  async logout(errorKey) {
    this.setState({ loaded: false });
    const tmpToken = this.state.tmpToken;
    const isServiceAdvisor = this.state.token?.isServiceAdvisor || false;
    if (!sessionErrors.includes(errorKey)) {
      const response = await axios.delete("/api/auth/logout", {
        params: {
          id: this.state?.token?.id,
          currentLongitude: this.state?.currentLongitude,
          currentLatitude: this.state?.currentLatitude,
        },
      });
      if (response?.data?.statusCode !== 200) return;
    }

    if (tmpToken) {
      this.persistObj("tmpToken", null);
      await this.setToken(tmpToken);
      await this.setState({ tmpToken: null });
    } else if (this.state?.token?.role == "wsadmin") {
      this.persistObj("tmpToken", null);
      await this.setToken(this.state.superToken);
      await this.setState({ tmpToken: null });
    } else if (this.state?.token?.role == "sadmin") {
      this.persistObj("superToken", null);
      await this.setState({ superToken: undefined });
      await this.setToken(null);
    }
    this.setState({ loaded: true });
    window.location = isServiceAdvisor === true ? "/service-advisor/login" : "/";
  }

  toggleSidebar(options = {}) {
    if (options.sidebarClose && !this.state.sidebarActive) {
      return;
    }
    this.setSidebarActive(!this.state.sidebarActive);
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((currentPosition) => {
        this.setState({
          currentLongitude: currentPosition.coords.longitude,
          currentLatitude: currentPosition.coords.latitude,
        });
      });
    }
  }
}

const appState = new AppStateContainer();

window.APP_STATE = appState;

export default appState;
