import React, { useEffect, useState } from "react";
import Layout from "../shared/Layout";
import HeaderTitle from "../shared/HeaderTitle";
import { FormattedMessage, injectIntl } from "react-intl";
import FieldRow from "../shared/FieldRow";
import { Form, Formik } from "formik";
import withRouter from "../../util/withRouter";
import { withAuthContext } from "../../state/AuthContext";
import { Yup } from "../../util";
import QuotationService from "../../services/quotationService";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import AutohubServices from "../../services/autohubServices";
import appState from "../../state/AppStateContainer";
import errorMessage from "../../util/errorMessage";
import { Button } from "react-bootstrap";

const selectionViewOptions = [
  // { value: "radio", label: "Radio" },
  { value: "drop-down", label: "Single Select" },
  { value: "checkbox", label: "Multi Select" },
  { value: "text", label: "Free Text" },
  { value: "image", label: "Upload Image" },
  { value: "checkboxCustom", label: "Checkbox" },
];

const QuotationForm = (props) => {
  const [data, setData] = useState(null);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAddQuestion = (values, setFieldValue) => {
    const newQuestion = {
      name_en: "",
      name_ar: "",
     // note:"",
      componentType: "",
    };

    const updatedQuestions = [...values.questions, newQuestion];

    setFieldValue("questions", updatedQuestions);
  };

  const handleAddOption = (values, setFieldValue, index) => {
    if (!values.questions[index].options) {
      setFieldValue(`questions[${index}].options`, [
        { value_en: "", value_ar: "" },
      ]);
    } else {
      const updatedOptions = [
        ...values.questions[index].options,
        { value_en: "", value_ar: "" },
      ];
      setFieldValue(`questions[${index}].options`, updatedOptions);
    }
  };

  const handleDeleteQuestion = (values, setFieldValue, questionIndex) => {
    const updatedQuestions = values.questions.filter(
      (_, index) => index !== questionIndex
    );

    setFieldValue("questions", updatedQuestions);
  };

  useEffect(() => {
    setUpForm();
  }, []);

  useEffect(() => {
    if (props.match.params.id) {
      fetchData();
    }
  }, [props.match.params.id]);

  const setUpForm = async () => {
    setLoading(true);
    try {
      const categories = await AutohubServices.getServiceCategories();
      setServiceCategories(categories);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
     
      setLoading(true);
      const data = await QuotationService.getQuotation(props.match.params.id);
      setData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching task data:", error);
    }
  };

  const handleDeleteOption = (
    values,
    setFieldValue,
    questionIndex,
    optionIndex
  ) => {
    const questionOptions = values.questions[questionIndex].options;

    const updatedOptions = questionOptions.filter(
      (_, index) => index !== optionIndex
    );

    setFieldValue(`questions[${questionIndex}].options`, updatedOptions);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      setLoading(true);
      const payload = {
        ...values,
        questions: JSON.stringify(values["questions"]),
      };
     
      let response;
      if (!props.match.params.id) {
        response = await QuotationService.addQuotation(payload);
      } else {
        const request = { id: props.match.params.id, ...payload };
        response = await QuotationService.updateQuotation(request);
      }

      NotificationManager.success(
        props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      props.navigate("/quotations", {
        isSubmitted: true,
      });
    } catch (e) {
      NotificationManager.error(
        props.intl.formatMessage({
          id:
            errorMessage?.Error[e?.response?.data?.statusCode]?.key ||
            "error.unexpectedError",
          defaultMessage:
            errorMessage?.Error[e?.response?.data?.statusCode]?.value ||
            "An unexpected error has occurred",
        }),
        props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );

      // NotificationManager.error(
      //   props.intl.formatMessage({
      //     id: _.get(e, "response.data.error", "error.unexpectedError"),
      //     defaultMessage: "An unexpected error has occurred",
      //   }),
      //   props.intl.formatMessage({
      //     id: "global.error",
      //     defaultMessage: "Error",
      //   })
      // );
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    quotationNameEn: Yup.string()
      .hasNoArabic(
        props.intl.formatMessage({
          id: "error.arabicCharactersAreNotAllowedInThisField",
          defaultMessage: "Arabic characters are not allowed in this field",
        })
      )
      .label(
        props.intl.formatMessage({
          id: "quotation.englishName",
          defaultMessage: "English name",
        })
      )
      .trim()
      .required(),
    quotationNameAr: Yup.string()
      .hasNoEnglish(
        props.intl.formatMessage({
          id: "error.englishCharactersAreNotAllowedInThisField",
          defaultMessage: "English characters are not allowed in this field",
        })
      )
      .label(
        props.intl.formatMessage({
          id: "quotation.arabicName",
          defaultMessage: "Arabic Name",
        })
      )
      .trim()
      .required(),
    questions: Yup.array()
      .of(
        Yup.object().shape({
          name_en: Yup.string().required("English name is required"),
            name_ar: Yup.string().required("Arabic name is required"),
          //  note: Yup.mixed().notRequired(),
          componentType: Yup.string().required("Selection View is required"),
          options: Yup.array()
            .of(
              Yup.object().shape({
                value_en: Yup.string().required("English value is required"),
                value_ar: Yup.string().required("Arabic value is required"),
              })
            )
            .when("componentType", {
              is: (componentType) =>
                componentType !== "text" && componentType !== "image"&& componentType !== "checkboxCustom",
              then: (schema) => schema.required("Options are required"),
              otherwise: (schema) => schema.notRequired(),
            }),
        })
      )
      .required("Questions are required"),

    englishDescription: Yup.string()
      .hasNoArabic(
        props.intl.formatMessage({
          id: "error.arabicCharactersAreNotAllowedInThisField",
          defaultMessage: "Arabic characters are not allowed in this field",
        })
      )
      .label(
        props.intl.formatMessage({
          id: "quotation.englishDescription",
          defaultMessage: "English description",
        })
      )
      .trim()
      .required(),
    arabicDescription: Yup.string()
      .label(
        props.intl.formatMessage({
          id: "blogs.arabicDescription",
          defaultMessage: "Arabic Description",
        })
      )
      .trim()
      .required(),
    serviceCode: Yup.string()
      .label(
        props.intl.formatMessage({
          id: "blogs.serviceCode",
          defaultMessage: "Service code",
        })
      )
      .trim()
      .required(),
    category: Yup.string()
      .label(
        props.intl.formatMessage({
          id: "service.category",
          defaultMessage: "Service category",
        })
      )
      .required(),
    image_en: Yup.mixed()
      .label(
        props.intl.formatMessage({
          id: "quotation.image",
          defaultMessage: "Quotation Image",
        })
      )
      .required(),
    image_ar: Yup.mixed()
      .label(
        props.intl.formatMessage({
          id: "quotation.image",
          defaultMessage: "Quotation Image",
        })
      )
      .required(),
  });

  const injectQuestions = (questions) => {
    const formattedData = questions?.map((obj) => {
      return {
        name_en: obj?.name_en,
        name_ar: obj?.name_ar,
      //note: obj?.note,
        componentType: obj?.componentType,
        options: obj?.options?.map((opt) => {
          return {
            value_en: opt?.value_en,
            value_ar: opt?.value_ar,
          };
        }),
      };
    });
    return formattedData;
  };

  return (
    <Layout loading={loading}>
      <HeaderTitle id="quotation.title" defaultMessage="Quotation Services" />

      <div className="dashboard-table-container card-layout">
        <header className="panel-title">
          {!props.match.params.id ? (
            <FormattedMessage
              id="quotation.addQuotation"
              defaultMessage="Add Quotation Service"
            />
          ) : (
            <FormattedMessage
              id="quotation.updateQuotation"
              defaultMessage="Update Quotation Service"
            />
          )}
        </header>

        <Formik
          enableReinitialize
          initialValues={{
            quotationNameEn: data?.name?.value_en || "",
            quotationNameAr: data?.name?.value_ar || "",
            englishDescription: data?.detail?.value_en || "",
            arabicDescription: data?.detail?.value_ar || "",
            image_en: data?.image_en || "",
            image_ar: data?.image_ar || "",
            serviceCode: data?.serviceCode || "",
            category: data?.categoryId,
            questions:
              data?.questions?.length > 0
                ? injectQuestions(data?.questions)
                : [],
            isActive: data?.isActive || false,
            isExpressAllowed: data?.isExpressAllowed || false,
          }}
          onSubmit={handleSubmit}
         validationSchema={validationSchema}
        >
          {(formikProps) => {
            const { values, setFieldValue, isSubmitting, errors } = formikProps;
            return (
              <Form>
                <div className="dashboard-row">
                  <FieldRow
                    type="text"
                    name="quotationNameEn"
                    defaultLabel="Quotation English Name"
                    labelId="quotation.englishName"
                    defaultPlaceholder="English name"
                    placeholderId="quotation.englishName"
                    disabled={false}
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    colSize={2}
                  />
                  <FieldRow
                    type="text"
                    name="quotationNameAr"
                    defaultLabel="Quotation Arabic Name"
                    labelId="quotation.arabicName"
                    defaultPlaceholder="Arabic name"
                    placeholderId="quotation.arabicName"
                    disabled={false}
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    colSize={2}
                  />
                  <FieldRow
                    type="html"
                    name="englishDescription"
                    labelId="quotation.descriptionEnglish"
                    defaultLabel="Quotation Description English"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    textEditor={true}
                  />
                  <FieldRow
                    type="html"
                    name="arabicDescription"
                    labelId="quotation.descriptionArabic"
                    defaultLabel="Quotation Description Arabic"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    textEditor={true}
                  />
                  <FieldRow
                    type="text"
                    name="serviceCode"
                    id="serviceCode"
                    labelId="quotation.serviceCode"
                    defaultLabel="Service Code"
                    placeholderId="quotation.serviceCode"
                    defaultPlaceholder="Enter Service Code"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />

                  <FieldRow
                    type="select"
                    name="category"
                    labelId="service.category"
                    defaultLabel="Category"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  >
                    <option value="">
                      {props.intl.formatMessage({
                        id: "service.category",
                        defaultMessage: "Select Category",
                      })}
                    </option>
                    {serviceCategories?.map((c) => {
                      return (
                        <option value={c?._id} key={c?._id}>
                          {c?.name["value_" + appState?.state?.language]}
                        </option>
                      );
                    })}
                  </FieldRow>

                  <FieldRow
                    name="image_en"
                    type="file"
                    image={true}
                    aspectRatio={325.8 / 325}
                    imageSize="W:325.8 x H:325"
                    cameraIcon={true}
                    showThumbnail={true}
                    labelId="quotation.image"
                    defaultLabel="English Image"
                    placeholderId="quotation.selectImage"
                    defaultPlaceholder="Select Image"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />
                  <FieldRow
                    name="image_ar"
                    type="file"
                    image={true}
                    aspectRatio={325.8 / 325}
                    imageSize="W:325.8 x H:325"
                    cameraIcon={true}
                    showThumbnail={true}
                    labelId="quotation.image"
                    defaultLabel="Arabic Image"
                    placeholderId="quotation.selectImage"
                    defaultPlaceholder="Select Image"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />
                  {values?.questions?.map((ques, questionIndex) => (
                    <div key={questionIndex} className="quotation-header">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormattedMessage
                          id={`quotation.question.${questionIndex + 1}`}
                          defaultMessage={`Question ${questionIndex + 1}`}
                        />
                        <div>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() =>
                              handleDeleteQuestion(
                                values,
                                setFieldValue,
                                questionIndex
                              )
                            }
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </div>
                      </div>
                      <div>
                        <FieldRow
                          type="text"
                          name={`questions[${questionIndex}].name_en`}
                          defaultLabel="English Name"
                          labelId="quotation.englishName"
                          defaultPlaceholder="english name"
                          placeholderId="quotation.englishName"
                          disabled={false}
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          colSize={2}
                        />
                        <FieldRow
                          type="text"
                          name={`questions[${questionIndex}].name_ar`}
                          defaultLabel="Arabic Name"
                          labelId="quotation.arabicName"
                          defaultPlaceholder="arabic name"
                          placeholderId="quotation.arabicName"
                          disabled={false}
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          colSize={2}
                        />
                        {/* <FieldRow
                          type="text"
                          name={`questions[${questionIndex}].note`}
                          defaultLabel="NOTE"
                          labelId="quotation.note"
                          defaultPlaceholder="Note"
                          placeholderId="quotation.note"
                          disabled={false}
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          colSize={2}
                        /> */}
                        <FieldRow
                          type="select"
                          name={`questions[${questionIndex}].componentType`}
                          defaultLabel="Choose Selection View"
                          labelId="quotation.selectionView"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          disabled={false}
                        >
                          <option value="" defaultValue="Please Choose">
                            {props.intl.formatMessage({
                              id: "quotation.pleaseChoose",
                              defaultMessage: "Please Choose",
                            })}
                          </option>
                          {selectionViewOptions?.map((item, j) => (
                            <option value={item.value} key={j}>
                              {item.label}
                            </option>
                          ))}
                        </FieldRow>
                        {values.questions[questionIndex]?.options?.map(
                          (opt, optionIndex) => (
                            <div className="quotation-option">
                              <FieldRow
                                type="text"
                                name={`questions[${questionIndex}].options[${optionIndex}].value_en`}
                                defaultLabel={`${
                                  optionIndex + 1
                                }) Option English`}
                                labelId="quotation.optionEnglish"
                                defaultPlaceholder="option name english"
                                placeholderId="quotation.optionEnglish"
                                disabled={false}
                                showRequiredFlag={true}
                                formikProps={formikProps}
                                colSize={2}
                              />
                              <FieldRow
                                type="text"
                                name={`questions[${questionIndex}].options[${optionIndex}].value_ar`}
                                defaultLabel={`${
                                  optionIndex + 1
                                }) Option Arabic`}
                                labelId="quotation.optionArabic"
                                defaultPlaceholder="option name arabic"
                                placeholderId="quotation.optionArabic"
                                disabled={false}
                                showRequiredFlag={true}
                                formikProps={formikProps}
                                colSize={2}
                              />
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() =>
                                  handleDeleteOption(
                                    values,
                                    setFieldValue,
                                    questionIndex,
                                    optionIndex
                                  )
                                }
                              >
                                <i className="fa fa-times" />
                              </button>
                            </div>
                          )
                        )}
                        <div className="row">
                          {values.questions[questionIndex]?.componentType &&
                            values.questions[questionIndex]?.componentType !==
                              "text" && values.questions[questionIndex]?.componentType !==
                              "image" && values.questions[questionIndex]?.componentType !==
                              "checkboxCustom" && (
                              <button
                                className="btn btn-primary pull-right"
                                type="button"
                                onClick={() =>
                                  handleAddOption(
                                    values,
                                    setFieldValue,
                                    questionIndex
                                  )
                                }
                              >
                                Add Option
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <button
                      type="button"
                      className="btn btn-success pull-right"
                      onClick={() => handleAddQuestion(values, setFieldValue)}
                    >
                      <FormattedMessage
                        id="quotation.addNewQuestion"
                        defaultMessage="Add New Question"
                      />
                    </button>
                  </div>
                  <FieldRow
                    name="isActive"
                    type="checkbox"
                    labelId="quotation.isActive"
                    defaultLabel="Active"
                    formikProps={formikProps}
                  />
                  <FieldRow
                    name="isExpressAllowed"
                    type="checkbox"
                    labelId="quotation.isExpressAllowed"
                    defaultLabel="Express Allowed"
                    formikProps={formikProps}
                  />
                </div>
                <div className="dashboard-row -nobottom text-right">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value={props.intl.formatMessage({
                      id: "global.submit",
                      defaultMessage: "Submit",
                    })}
                    disabled={isSubmitting}
                  />
                  <Button
                    variant="link"
                    onClick={() => props.navigate("/quotations")}
                  >
                    {props.intl.formatMessage({
                      id: "global.cancel",
                      defaultMessage: "Cancel",
                    })}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default withAuthContext(withRouter(injectIntl(QuotationForm)));
