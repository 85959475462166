import React from "react";
import { Accordion, Tab as Panel, Tabs as PanelGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Step, StepTitle, Stepper } from "react-progress-stepper";
import appState from "../../../state/AppStateContainer";
import CheckAccess from "../../shared/CheckAccess";
import { ActivitiesEnum } from "../../../staticData/Activities";
import { repairStatusNames } from "../../../util/utils";

const Appointments = ({
  type,
  progress,
  fastCollectBranch,
  handleDisabledButton,
  setFirstCarDetails,
  handleCarProgressModal,
  setShowRecommendationModal,
  handleViewRecommendations,
}) => {
  const lang = appState.state.language;

  const createMasterStep = (masterStatus) => {
    return repairStatusNames[type].map((stepName, x) => {
      const stepTitle = (
        <StepTitle>
          <FormattedMessage
            id={"track." + stepName}
            defaultMessage={stepName}
          />
        </StepTitle>
      );

      return x < 3 && masterStatus == x ? (
        <Step customContent={() => <div className="current-step" />} key={x}>
          {stepTitle}
        </Step>
      ) : x > 2 ? (
        <Step customContent={() => <i className="fa fa-trophy" />} key={x}>
          {stepTitle}
        </Step>
      ) : (
        <Step key={x}>{stepTitle}</Step>
      );
    });
  };

  const createSubSteps = (status) => {
    return repairStatusNames[type].map((stepName, i) => {
      return i < 3 && status == i ? (
        <Step customContent={() => <div className="current-step" />} key={i} />
      ) : i > 2 ? (
        <Step customContent={() => <i className="fa fa-trophy" />} key={i} />
      ) : (
        <Step key={i} />
      );
    });
  };

  return (
    <div className="progress-tracker">
      <div className="row m-auto">
        <Accordion defaultActiveKey={0}>
          {progress.length ? (
            progress.map((carsEstimation, i) => {
              const carDetails = carsEstimation[0];
              const masterStatus = carDetails.status;
              let estimatedDate = carDetails.actual_promise_date[lang];
              estimatedDate = estimatedDate ? estimatedDate + "*" : "";
              const carPlateNumber =
                carDetails?.plateNumber?.length &&
                carDetails?.plateLetter?.length
                  ? carDetails.plateNumber + carDetails?.plateLetter
                  : carDetails?.plateNumber;
              const make = carDetails.make;
              const model = carDetails.model;

              console.log(">>>", carDetails);

              return (
                <Accordion.Item eventKey={i} key={i}>
                  <div>
                    <Accordion.Header className="car-progress">
                      {/* <Panel.Title toggle> */}
                      <div className="col-md-3 status-for panel-title">
                        <h5>{carDetails.name}</h5>
                        {type === "appointments" && (
                          <span className="fa">
                            <span className="more-details">
                              <FormattedMessage
                                id="track.moreDetails"
                                defaultMessage="More details"
                              />
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="col-md-9">
                        {estimatedDate && (
                          <p className="estimated-date">
                            <FormattedMessage
                              id="track.estimatedDate"
                              defaultMessage="*Estimated repair completion date: "
                            />
                            {estimatedDate}
                          </p>
                        )}
                        <Stepper step={masterStatus} numbered={false}>
                          {createMasterStep(masterStatus)}
                        </Stepper>
                      </div>
                      {/* <div className="col-md-1">
                                <i className={`fa pull-right ${selectedPanel === i + 1 ? "fa-minus" : "fa-plus"}`} />
                              </div> */}
                      {/* </Panel.Title> */}
                    </Accordion.Header>

                    {appState.state.token?.role === "sadmin" && (
                      <>
                        <div className="fast-collect-controls">
                          {fastCollectBranch?.[i]?.fastCollect ? (
                            <div>
                              <CheckAccess
                                activityId={
                                  ActivitiesEnum.add_car_progress_button
                                }
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={handleDisabledButton(
                                    carDetails,
                                    type
                                  )}
                                  onClick={() => {
                                    if (handleDisabledButton(carDetails, type))
                                      return;
                                    handleCarProgressModal("add", {
                                      carPlateNumber,
                                      make,
                                      model,
                                      branchCode: carDetails?.branchCode,
                                    });
                                  }}
                                >
                                  <FormattedMessage
                                    id="track.addFastCollect"
                                    defaultMessage="Add Car Progress"
                                  />
                                </button>
                              </CheckAccess>

                              <CheckAccess
                                activityId={
                                  ActivitiesEnum.view_repair_history_button
                                }
                              >
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    handleCarProgressModal("view", {
                                      carPlateNumber,
                                    })
                                  }
                                >
                                  <FormattedMessage
                                    id="track.viewFastCollect"
                                    defaultMessage="View Repair History"
                                  />
                                </button>
                              </CheckAccess>
                            </div>
                          ) : null}

                          {type === "appointments" && (
                            <div>
                              <CheckAccess
                                activityId={
                                  ActivitiesEnum.add_recommendation_button
                                }
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={handleDisabledButton(
                                    carDetails,
                                    type
                                  )}
                                  onClick={() => {
                                    if (handleDisabledButton(carDetails, type))
                                      return;
                                    setFirstCarDetails(carsEstimation[0]);
                                    setShowRecommendationModal({
                                      add: true,
                                    });
                                  }}
                                >
                                  <FormattedMessage
                                    id="track.addRecommendation"
                                    defaultMessage="Add Recommendation"
                                  />
                                </button>
                              </CheckAccess>

                              <CheckAccess
                                activityId={
                                  ActivitiesEnum.view_recommended_Offers_button
                                }
                              >
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    handleViewRecommendations(carPlateNumber)
                                  }
                                >
                                  <FormattedMessage
                                    id="track.viewRecommendedOffers"
                                    defaultMessage="View Recommended Offers"
                                  />
                                </button>
                              </CheckAccess>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {type === "appointments" && (
                      <Accordion.Body>
                        <div className="row">
                          <div className="per-car-progress">
                            {carsEstimation.map((data, x) => {
                              return (
                                <div className="row" key={x}>
                                  <div className="col-md-3 status-for">
                                    <h6>
                                      <span>{data?.estimation_ref_num}</span>
                                      <br />
                                      <span>
                                        {data.serviceType["value_" + lang]}
                                      </span>
                                    </h6>
                                  </div>
                                  <div className="col-md-8 detail-body">
                                    <Stepper
                                      step={data?.status}
                                      numbered={false}
                                    >
                                      {createSubSteps(data?.status)}
                                    </Stepper>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Accordion.Body>
                    )}
                  </div>
                </Accordion.Item>
              );
            })
          ) : !progress.length ? (
            <h5 className="no-data">
              <FormattedMessage
                id="track.noAppointmentData"
                defaultMessage="No Appointments"
              />
            </h5>
          ) : null}
        </Accordion>
      </div>
    </div>
  );
};

export default Appointments;
